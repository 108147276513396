import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Link,
  Redirect
} from "react-router-dom";
import { fetchCurrentUser, createAffiliateId, addAffiliateReferrer, affiliateNoThanks } from 'state/workflows/fetch-current-user/action-creators';
import { fetchArtists } from 'state/workflows/read-artists/action-creators';
import StyledParagraph from 'design/atoms/styled-paragraph';
import { updateEditingMerchDetails } from 'state/workflows/edit-merch/action-creators';
import Input from 'design/atoms/input';
import Logo from 'design/atoms/logo';
import Button from 'design/atoms/button';
import { toggleAddMerch } from 'state/workflows/add-merch/action-creators';
import StyledHeader from 'design/atoms/styled-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import { faCheck, faBan, faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Toggle from 'design/atoms/toggle';
import TagList from 'design/atoms/tag-list';
import Form from 'design/templates/application-form';

const Dashboard = styled.div`
  flex-direction: column;
  display: flex;
  min-height: 100%;

  label {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 0) {
    &.partner-questionaire {
      padding: 0 ${ whitespace('least') } ${ whitespace('less') };

      p {
        width: auto;
      }
    }

  }

  @media only screen and (min-width: 1200px) {
    &.partner-questionaire {
      padding: 0 ${ whitespace('most') } ${ whitespace('most') };

      p {
        width: 66.6%;
      }
    }
  }
`;

function generateResizeUrl(key, width, height) {
  const domain = 'https://d2m5fus4qpj770.cloudfront.net/';
  const bucket = 'merchgame204211-production';

  return `${domain}${btoa(JSON.stringify({
    bucket,
    key: `public/${ key }`,
    edits: {
      resize: {
        width,
        height,
        fit: 'cover'
      }
    }
  }))}`;
}

class RegisterPartner extends React.Component {
  componentWillUnmount() {
    document.querySelector('.application-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  render() {
    if (!this.props.currentUser?.sub) {
      return ( <Redirect to="/sign-in" /> );
    } else {
      return (
        <Dashboard className="partner-questionaire">
          <Form>
            <StyledParagraph>
              If you like what we're doing and <strong>want to help build the community</strong>, you can <strong>create a Partner Id now.</strong>
            </StyledParagraph>

            <Input
              autoFocus={ true }
              label='Get My Partner Id'
              disabled={ this.props.affiliateId && this.props.affiliateId !== 'NO_THANKS'}
              placeholder='Get the credit you deserve. Create a Partner Id.'
              value={ this.props.affiliateId === 'NO_THANKS' ? '' : (this.props.affiliateId ? this.props.affiliateId : '') }
              onBlur={(e) => {
                this.props.createAffiliateId(e.target.value);
              }}
            />

            <StyledParagraph>
              If you were <strong>referred by a friend, they may have given you their PartnerId.</strong> <strong>If so, enter it below,</strong> that's how we make sure they get credit.
            </StyledParagraph>

            <Input
              label='Who Sent You?'
              disabled={ this.props.affiliateReferrer && this.props.affiliateReferrer !== 'NO_THANKS'}
              placeholder='If you were given a Partner ID, enter it here.'
              value={ this.props.affiliateReferrer === 'NO_THANKS' ? '' : (this.props.affiliateReferrer ? this.props.affiliateReferrer : '') }
              onBlur={(e) => {
                this.props.addAffiliateReferrer(e.target.value);
              }}
            />

            <StyledParagraph>
              To find out more about how we reward our Partners, visit our <Link to="/partners">Partners page</Link>.
            </StyledParagraph>

          </Form>
        </Dashboard>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    affiliateId: state.fetchCurrentUser.affiliateId,
    affiliateReferrer: state.fetchCurrentUser.affiliateReferrer,
    merch: state.fetchUserArtist.currentUserArtist.merch.filter((m) => (m.status === 'SAVED' || m.status === 'INACTIVE')),
    currentUserArtist: state.fetchUserArtist.currentUserArtist,
    id: state.fetchUserArtist.currentUserArtist.id,
    artists: state.readArtists.artists,
    currentUser: state.fetchCurrentUser.currentUser,
    artistsFetched: state.readArtists.artistsFetched,
    registrationStatus: state.fetchUserArtist.currentUserArtist.registrationStatus
  }
};

const mapDispatchToProps = {
  fetchCurrentUser,
  fetchArtists,
  toggleAddMerch,
  updateEditingMerchDetails,
  createAffiliateId,
  addAffiliateReferrer,
  affiliateNoThanks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPartner);
