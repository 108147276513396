import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery, select } from 'redux-saga/effects';

import {
  queryForMerch
} from 'graphql/queries';

import {
  FETCH_MERCH,
  ERRORED_FETCH_MERCH,
  LIST_MERCH,
  UPDATE_FILTERS,
  TOGGLE_FILTERS
} from 'state/workflows/read-merch/action-types';

const fetchMerchRequest = async ({
  genres,
  locationCoordinates,
  maxPrice,
  categories
}) => {
  return await API.graphql({
    query: queryForMerch,
    authMode: 'API_KEY',
    variables: {
      input: {
        genres,
        locationCoordinates
      }
    }
  });
};

function* fetchMerchAsync(action) {
  const state = yield select();

  if (
    action.type === 'UPDATE_FILTERS' &&
    (!Object.keys(action.payload).includes('locationFilter') &&
    !Object.keys(action.payload).includes('genreFilters'))
  ) {
    return;
  }

  try {
    const response = yield fetchMerchRequest({
      genres: state.readMerch.filters.genreFilters,
      locationCoordinates: state.readMerch.filters.locationFilter
    });

    const merch = response.data.queryForMerch.data.reduce((sum, current) => {
      return [
        ...sum,
        ...current.merch.map((m) => {
          return {
            ...m,
            artist: {
              name: current.name,
              locationLabel: current.locationLabel,
              genres: current.genres,
              description: current.description,
              logoKey: current.logoKey,
              contactPoints: current.contactPoints,
              links: current.links
            }
          }
        })
      ];
    }, []);

    yield put({
      type: LIST_MERCH,
      payload: merch
    });
  } catch (e) {
    console.error('fetchMerchAsync error:', e);
    yield put({ type: ERRORED_FETCH_MERCH });
  }
};


const sagas = function* watchFetchMerchAsync() {
  yield takeEvery(FETCH_MERCH, fetchMerchAsync);
  yield takeEvery(UPDATE_FILTERS, fetchMerchAsync);
};

export default sagas;
