import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';

export default styled.h1`
  font-weight: 100;
  letter-spacing: 2px;
  text-align: right;
  color: ${ color('grey', 'dark') };
  margin: 0;
  background: #fff;
  z-index: 999;
  left: 0;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    color: #d5d5d5;
    position: relative;
    top: -2px;
  }

  a {
    text-decoration: none;
    color: ${ color('blue', 'lightest') };
  }

  @media only screen and (min-width: 0) {
    padding: ${ whitespace('least') };
    font-size: 1rem;

    svg {
      margin: 0 ${ whitespace('least') };
      font-size: .85rem;
      top: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: ${ whitespace('least') } ${ whitespace('less') };
    font-size: 2rem;
    top: 53px;

    svg {
      margin: 0 ${ whitespace('less') };
      font-size: 1.5rem;
      top: -2px;
    }
  }

`;
