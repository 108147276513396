import React from 'react';
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTshirt } from '@fortawesome/free-solid-svg-icons'

const StyledButton = styled.button`
  color: #fff;
  display: block;
  margin: ${ whitespace('less') };
  padding: ${ whitespace() };
  text-transform: uppercase;
  letter-spacing: 2px;
  background: ${ color('blue') };

  &:hover:not(:disabled) {
    background-color: #fff;
    color: ${ color('blue') };
    border: 1px solid ${ color('blue') };
    cursor: pointer;
  }

  svg {
    margin-left: ${ whitespace('least') };
  }

  &:disabled {
    border-color: ${ color('grey', 'lighter') };
    color: ${ color('grey', 'lighter') };
  }

  @media only screen and (min-width: 0) {
    width: calc(100% - ${ whitespace('less') });
    margin: ${ whitespace('least') };
    border-radius: ${ whitespace('least') };
    font-size: 1.15rem;
    border-width: 1px;
    padding: ${ whitespace('less') };

    svg {
      margin: 0 ${ whitespace('less') } 0 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    width: calc(100% - ${ whitespace('less') } - ${ whitespace('less') });
    margin: ${ whitespace('less') };
    border-radius: ${ whitespace('least') };
    padding: ${ whitespace() };
    font-size: 2.5rem;
    border-width: 1px;

    svg {
      margin: 0 ${ whitespace() };
    }
  }

`;

export default class Button extends React.Component {
  render() {
    return (
      <StyledButton disabled={ this.props.disabled } type={ this.props.type } onClick={ this.props.action }>
        { this.props.label }
        { this.props.processing &&
          <FontAwesomeIcon spin icon={ faTshirt } />
        }
      </StyledButton>
    );
  }
};
