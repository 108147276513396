import {
  EDIT_MERCH,
  TOGGLE_EDIT_MERCH,
  UPDATE_MERCH_ITEM_EDITING_ID,
  UPDATE_EDITING_MERCH_DETAILS,
  DEACTIVATE_MERCH
} from 'state/workflows/edit-merch/action-types';

export const editMerch = merch => ({
  type: EDIT_MERCH,
  payload: merch
});

export const toggleEditMerch = (merchId) => ({
  type: TOGGLE_EDIT_MERCH,
  payload: merchId
});

export const updateMerchItemEditingId = (merchId) => ({
  type: UPDATE_MERCH_ITEM_EDITING_ID,
  payload: merchId
});

export const updateEditingMerchDetails = (id, key, value) => ({
  type: UPDATE_EDITING_MERCH_DETAILS,
  payload: { id, key, value }
});

export const deactivateMerch = (merch) => ({
  type: DEACTIVATE_MERCH,
  payload: merch
});
