import {
  INITIALIZATION_COMPLETE
} from 'state/workflows/initialization/action-types';

const initialState = {
  isInitializing: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALIZATION_COMPLETE: {
      return {
        ...state,
        isInitializing: false
      };
    }

    default:
      return state;
  }
};
