import { Auth } from 'aws-amplify';
import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faUser, faSignOutAlt, faSignInAlt, faSearch, faTag, faTshirt, faListAlt, faUsers } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import Logo from 'design/atoms/logo';
import { fetchCurrentUser, addAffiliateReferrer, createAffiliateId, affiliateNoThanks } from 'state/workflows/fetch-current-user/action-creators';
import { fetchArtists } from 'state/workflows/read-artists/action-creators';
import Tooltip from 'design/atoms/tooltip';
import Input from 'design/atoms/input';

const Header = styled.header`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  height: 50px;
  box-sizing: border-box;
  background: ${ color('grey', 'darkest')};
  border-bottom: 1px solid ${ color('grey', 'darker')};

  a {
    text-decoration: none;
  }

  .brand-logo {
    color: ${ color('blue') };
    text-shadow: 2px 2px 1px #000;

    &:hover {
      color: ${ color('grey', 'lighter') };
    }
  }

  @media only screen and (min-width: 0) {
    padding: 0;

    .brand-logo {
      margin: 0 ${ whitespace('least') } 0 ${ whitespace('least') };
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: ${ whitespace('least') };

    .brand-logo {
      margin: 0 ${ whitespace('less') };
    }
  }
`;

const NavList = styled.nav`
  display: flex;
  flex-grow: 1;
`;

const NavLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  color: ${ color('grey') };
  font-size: 16px;
  line-height: 50px;
  text-shadow: 1px 1px 1px #000;
  align-items: center;
  justify-content: center;
  .merch-count {
    font-weight: bold;
  }

  svg {
    color: ${ color('blue') };
  }

  &:hover {
    cursor: pointer;
    color: ${ color('grey', 'light') };

    svg {
      path {
        color: ${ color('grey', 'lighter') };
      }
    }
  }

  &[aria-current='page'] {
    cursor: default;
  }

  @media only screen and (min-width: 0) {
    padding: 0 ${ whitespace('least') };
    margin: 0;

    span {
      display: none;
    }

    svg {
      font-size: 1rem;
      padding: 0 ${ whitespace('least') };
      margin: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 0 ${ whitespace('less') };
    margin: 0 ${ whitespace('least') };

    span {
      display: inline;
    }

    svg {
      font-size: 1.35rem;
      margin: 0 ${ whitespace('least') } 0 0;
    }
  }
`;

const SessionDetails = styled.div`
  display: flex;
  text-align: right;
  color: ${ color('blue') };
  margin-top: -2px;

  svg {
    font-size: 1.25rem;

    &:hover {
      cursor: pointer;

      path {
        color: #00bfff;

      }
    }
  }
`;

const Account = styled.span`
  padding: 0 ${ whitespace('less') };

  a {
    svg {
      color: ${ color('blue') };
    }
  }

  &:hover {
    a {
      svg {
        cursor: pointer;
        color: ${ color('grey', 'lighter') };
      }
    }
  }

  @media only screen and (min-width: 0) {
    padding: 0 ${ whitespace('least') };

    svg {
      font-size: 1rem;
      padding: 0 ${ whitespace('least') };
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 0 ${ whitespace('less') };
  }
`;

const SignInOut = styled(Link)`
  padding: 0 ${ whitespace('less') };

  svg {
    color: ${ color('blue') };

    &:hover {
      cursor: pointer;

      path {
        color: ${ color('grey', 'lighter') };
      }
    }
  }

  @media only screen and (min-width: 0) {
    padding: 0 ${ whitespace('least') };

    span {
      display: none;
    }

    svg {
      font-size: 1rem;
      padding: 0 ${ whitespace('least') };
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 0 ${ whitespace('less') };

    span {
      display: inline;
    }

    svg {
      font-size: 1.35rem;
    }
  }
`;

const ApplicationHeader = class ApplicationHeader extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchArtists();
  }
  render() {
    return (
      <Header className='application-header'>
        <Link to="/">
          <Logo />
        </Link>
        <NavList>
          <NavLink to='/browse'>
            <FontAwesomeIcon title="Search for new merch and music" icon={ faSearch } />
            <span>Find New Merch &amp; Music</span>
          </NavLink>

          <NavLink to='/pricing'>
            <FontAwesomeIcon title="Free features and Pro Account pricing" icon={ faTag } />
            <span>Pricing &amp; Features</span>
          </NavLink>

          <NavLink to='/partners'>
            <FontAwesomeIcon title="MerchGame partners collectively own 10%" icon={ faUsers } />
            <span>Become a Partner</span>
          </NavLink>

          { this.props.currentUserArtist.id &&
            this.props.currentUserArtist.registrationStatus === 'COMPLETED' &&
            <NavLink to='/dashboard'>
              <FontAwesomeIcon title="Merch Catalog and Artist Details" icon={ faListAlt } />
              <span>{ this.props.currentUserArtist.name } Merch &amp; Music (<span className="merch-count">{ this.props.currentUserArtist.merch.length || 0 }</span>)</span>
            </NavLink>
          }

          { this.props.currentUserArtist.registrationStatus !== 'COMPLETED' &&
            <NavLink to='/new-artist'>
              <FontAwesomeIcon title="Free features and Pro Account pricing" icon={ faTshirt } />
              <span>Join FREE! and create an Artist Profile</span>
            </NavLink>
          }
        </NavList>

        { this.props.currentUser &&
          this.props.affiliateId &&
          this.props.affiliateReferrer &&
          <SessionDetails>
            <Account>
              <Link to="/partner">
                <FontAwesomeIcon title={ this.props.currentUser.email } icon={ faUser } />
              </Link>
            </Account>
          </SessionDetails>
        }

        { this.props.currentUser &&
          <SessionDetails>
            <SignInOut to="/sign-in" onClick={(e) => Auth.signOut()}>
              <FontAwesomeIcon title="Sign Out" icon={ faSignOutAlt } />
            </SignInOut>
          </SessionDetails>
        }

        { !this.props.currentUser &&
          <SessionDetails>
            <SignInOut to="/sign-in">
              <FontAwesomeIcon title="Sign In" icon={ faSignInAlt } />
            </SignInOut>
          </SessionDetails>
        }
      </Header>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.fetchCurrentUser.currentUser,
    currentUserArtist: state.fetchUserArtist.currentUserArtist,
    affiliateId: state.fetchCurrentUser.affiliateId,
    affiliateReferrer: state.fetchCurrentUser.affiliateReferrer,
    artistsFetched: state.readArtists.artistsFetched
  }
};

const mapDispatchToProps = {
  fetchCurrentUser,
  fetchArtists,
  createAffiliateId,
  addAffiliateReferrer,
  affiliateNoThanks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationHeader);
