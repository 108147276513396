import styled from 'styled-components'
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 ${ whitespace('most') };
  padding: 0 ${ whitespace('most') };
  flex-wrap: wrap;

  svg {
    padding: 0 ${ whitespace('least') };
    color: ${ color('blue') };

    &:hover {
      cursor: pointer;
    }
  }
`;
