import React from 'react';
import styled from 'styled-components';
import whitespace from 'design/subatomics/whitespace';
import color from 'design/subatomics/colors';
import Logo from 'design/atoms/logo';
import Break from 'design/atoms/break';
import StyledParagraph from 'design/atoms/styled-paragraph';
import CallToAction from 'design/atoms/call-to-action';
import StyledHeader from 'design/atoms/styled-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faUser, faSignOutAlt, faSignInAlt, faSearch, faTag, faTshirt, faListAlt } from '@fortawesome/free-solid-svg-icons'

const MarketingPage = styled.article`

  p {
    width: 100%;
    line-height: 2.5rem;
    font-size: 1.15rem;

    padding-top: ${ whitespace('less') };
    padding-bottom: ${ whitespace('less') };

    &.corona {
      text-decoration: line-through;
    }

    .brand-logo {
      font-size: 1.85rem;
    }

  }

  .corona {
    text-decoration: line-through;
  }

  svg {
    position: fixed;
    color: ${ color('blue') };
  }

  @media only screen and (min-width: 0) {
    margin: ${ whitespace('less') };

    svg {
      opacity: .2;
      top: calc(${ whitespace() } + ${ whitespace('more') });
      right: ${ whitespace('less') };
      font-size: 35vw;
    }
  }

  @media only screen and (min-width: 1200px) {
    margin: ${ whitespace('more') } ${ whitespace('most') };
    padding: 0 ${ whitespace('more') };
    margin-left: 35%;

    svg {
      opacity: .35;
      top: calc(110px + ${ whitespace('most') });
      left: calc(17.2% - 10vw);
      font-size: 20vw;
    }
  }

`;

export default class PricingPage extends React.Component {
  componentWillUnmount() {
    document.querySelector('.application-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  render() {
    return (
      <div>
        <StyledHeader>Pricing Options</StyledHeader>
        <MarketingPage>
          <FontAwesomeIcon icon={ faTag } />

          <StyledParagraph>
            <Logo /> isn't an online store, it's an advertising platform. An Ad Link can point to your online store, video content on YouTube, your website, essentially anything with a URL. We make it easier for potential fans to find you with relevant filters, regardless of what online store you use.
          </StyledParagraph>

          <h2>A Note about COVID-19</h2>

          <StyledParagraph>
            Our primary goal is to help our artists expand their audience and sell more merch, and help our fans find killer new gear and music. But our secondary goal is also to run a profitable business working with a commuity we're passionate about. But at this exact moment, profit doesn't just take a back seat, it gets out of the car.
          </StyledParagraph>

          <StyledParagraph>
            With that said, <Logo /> is TOTALLY FREE until our Artists can get back on stage. You can see below how pricing will work in the future, but for Artists that sign up now, our whole platform will be FREE to them FOREVER. Depending on how quickly the community grows, we may need to turn to advertising to keep the servers running, but we'll avoid that as long as is reasonable.
          </StyledParagraph>

          <StyledParagraph className="corona">
            A FREE Account can have up to 3 active Ads. FREE Account content is also sorted after Premium Account content in browsing results. Still FREE though!
          </StyledParagraph>

          <CallToAction to="/dashboard">
            Promote Merch for FREE
          </CallToAction>

          <StyledParagraph className="corona">
            Some artists have a lot of merch, or just want their Ads to get Premium Placement.
          </StyledParagraph>

          <StyledParagraph className="corona">
            Premium Accounts get UNLIMITED<sup>*</sup> Ad Links and Premium Placement for those Ads. You'll have plenty of room to grow for only $5/MONTH.
          </StyledParagraph>

          <CallToAction to="/dashboard" className="corona">
            Get a Premium Account for $5/MONTH
          </CallToAction>

          <p><sup>*</sup> It's not actually unlimited, but it's a lot. We can discuss special pricing for Artists that need more.</p>

        </MarketingPage>
      </div>
    );
  }
};
