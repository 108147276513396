import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

import {
  createTag
} from 'graphql/mutations';

import {
  TOGGLE_NEW_GENRE
} from 'state/workflows/create-artist/action-types';

import {
  FETCH_TAGS
} from 'state/workflows/read-tags/action-types';

import {
  CREATE_TAG
} from 'state/workflows/create-tag/action-types';

const createTagRequest = async (input) => {
  return await API.graphql(graphqlOperation(createTag, { input }));
};

function* createTagAsync({ payload }) {
  yield put({ type: TOGGLE_NEW_GENRE });
  yield createTagRequest(payload);
  yield put({ type: FETCH_TAGS });
};

const sagas = function* watchCreateTagAsync() {
  yield takeEvery(CREATE_TAG, createTagAsync);
};

export default sagas;
