import React from 'react';
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  flex-grow: 1;
  flex-basis: 100%!important;

  section {
    flex-grow: 1;
  }

  footer {
    border-top: 1px solid ${ color('grey', 'lighter') };
    flex-grow: 0;
  }

  .input {
    flex-basis: 100%!important;
  }

  @media only screen and (min-width: 0) {
    section {
      padding: 0 ${ whitespace('less') } ${ whitespace('less') } ${ whitespace('less') };
    }

    footer {
      padding: ${ whitespace('least') };
    }
  }

  @media only screen and (min-width: 1200px) {
    section {
      padding: 0 ${ whitespace('less') } ${ whitespace('more') } ${ whitespace('more') };
    }

    footer {
      padding: ${ whitespace('least') };
    }
  }

`;

export default class ApplicationForm extends React.Component {
  render() {
    return (
      <Form onSubmit={ this.props.onSubmit } className='application-form'>
        { this.props.children }
      </Form>
    );
  }
};
