import {
  UPDATE_ARTIST,
  TOGGLE_UPDATE_ARTIST
} from 'state/workflows/update-artist/action-types';

export const updateArtist = (artist, isFinalizingCreationProcess = false) => ({
  type: UPDATE_ARTIST,
  payload: artist,
  isFinalizingCreationProcess
});

export const toggleUpdateArtist = () => {
  return {
    type: TOGGLE_UPDATE_ARTIST
  }

};
