import React from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux';
import {
  Redirect
} from "react-router-dom";
import { createTag } from 'state/workflows/create-tag/action-creators';
import Input from 'design/atoms/input';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';

const FilterWrapper = styled.div`
  width: 750px;
  background-color: ${ color('grey', 'lightest') };
  border: 1px solid ${ color('grey', 'lighter') };

  p {
    text-align: right;
    color: ${ color('grey', 'light') };
    font-size: 12px;
    margin-top: -.35rem;
    padding: ${ whitespace('least') } ${ whitespace('least') } ${ whitespace('less') };
  }

  h3 {
    text-align: right;
    color: ${ color('grey') };
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    margin: 0;
    padding: ${ whitespace('least') };
  }

`;

class CreateTag extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      category: this.props.category,
      label: ''
    };

    this.state = this.initialState;
  }

  handleSubmit(e) {
    console.log('handleSubmit', e);
    if (e.key === "Enter") {
      const { category, label } = this.state;
      this.setState(this.initialState);

      this.props.createTag({ category, label });
    }
  }

  render() {
    return (
      <FilterWrapper>
        { console.log(this.state) }

        <h3>{ this.props.title }</h3>
        <p>{ this.props.subtitle }</p>
          <Input
            autoFocus={ true }
            placeholder={ this.props.placeholder }
            value={ this.state.label }
            onChange={(e) => this.setState({ label: e.target.value })}
            onKeyPress={(e) => this.handleSubmit(e) }
            clearOnEnter={ true }
          />
      </FilterWrapper>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.fetchCurrentUser.currentUser
  }
};

const mapDispatchToProps = {
  createTag
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTag);
