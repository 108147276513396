import {
  ARTIST_CREATED
} from 'state/workflows/create-artist/action-types';

import {
  UPDATE_USER_ARTIST,
  UPDATE_NEW_ARTIST_DETAILS
} from 'state/workflows/fetch-user-artist/action-types';

import {
  UPDATE_MERCH_DETAILS
} from 'state/workflows/add-merch/action-types';

import {
  UPDATE_EDITING_MERCH_DETAILS
} from 'state/workflows/edit-merch/action-types';


const initialState = {
  currentUserArtist: {
    registrationStatus: null,
    id: '',
    name: '',
    description: '',
    locationLabel: '',
    locationCoordinates: '',
    logoKey: null,
    genres: [],
    contactPoints: [],
    links: [],
    merch: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ARTIST_CREATED: {
      return {
        ...state,
        currentUserArtist: {
          ...state.currentUserArtist,
          id: action.payload.id || '',
          name: action.payload.name || '',
          locationLabel: action.payload.locationLabel || {},
          locationCoordinates: action.payload.locationCoordinates || {},
          genres: action.payload.genres || [],
          registrationStatus: action.payload.registrationStatus || '',
          contactPoints: action.payload.contactPoints || [],
          links: action.payload.links || [],
          logoKey: action.payload.logoKey || '',
          description: action.payload.description || '',
          merch: action.payload.merch || []
        }
      };
    }

    case UPDATE_USER_ARTIST: {
      if (!action.payload) {
        return state;
      } else {
        return {
          ...state,
          currentUserArtist: {
            ...state.currentUserArtist,
            id: action.payload.id || '',
            name: action.payload.name || '',
            locationLabel: action.payload.locationLabel || '',
            locationCoordinates: action.payload.locationCoordinates || '',
            genres: action.payload.genres || [],
            registrationStatus: action.payload.registrationStatus || '',
            contactPoints: action.payload.contactPoints || [],
            links: action.payload.links || [],
            logoKey: action.payload.logoKey || '',
            description: action.payload.description || '',
            merch: action.payload.merch || []
          }
        };
      }
    }

    case UPDATE_EDITING_MERCH_DETAILS: {
      const { id, key, value } = action.payload;
      let newState = { ...state };

      newState.currentUserArtist.merch.find(m => m.id === id)[key] = value;
      return newState;
    }

    case UPDATE_NEW_ARTIST_DETAILS: {
      return {
        ...state,
        currentUserArtist: {
          ...state.currentUserArtist,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};
