import React from 'react';
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';

// Styles

const StyledInput = styled.input`
  display: inline-block;
  box-sizing: border-box;
  border-radius: ${ whitespace('most') };
  border: 1px solid ${ color('grey', 'light') };
  color: ${ color('grey', 'dark') };
  outline: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  &.checked {
    background-color: #101e26;
    color: ${ color('grey', 'lightest') };
    border: 1px solid #101e26;

    &:hover {
      border: 1px solid ${ color('blue') };
    }
  }

  &.not-checked {
    background-color: ${ color('grey', 'lighter') };
    color: ${ color('grey', 'darkest') };

    &:hover {
      color: ${ color('blue') };
      border: 1px solid ${ color('blue') };
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: 1px solid ${ color('grey', 'darkest') };
  }

  @media only screen and (min-width: 0) {
    padding: 2px ${ whitespace('least') };
    font-size: .65rem;
    margin: 3px;
  }

  @media only screen and (min-width: 1200px) {
    padding: ${ whitespace('least') } ${ whitespace('less') };
    font-size: ${ fontSize('smaller') };
    margin: ${ whitespace('least') };
  }

`;

// Required Properties
// this.props = {
//   label,
//   name,
//   value,
//   title,
//   placeholder,
//   alt,
//   type,
//   disabled,
//   autoFocus,
//   required,
//   onClick
// }

export default class Toggle extends React.Component {
  render() {
    return (
      <StyledInput
        className={ this.props.className }
        name={ this.props.name }
        value={ this.props.label }
        title={ this.props.title }
        alt={ this.props.alt }
        type="button"
        disabled={ this.props.disabled || false }
        onClick={ this.props.onClick || function() {} }
      />
    );
  }
};
