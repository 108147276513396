import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

// import {
//   updateMerch
// } from 'graphql/mutations';

import {
  FETCH_ARTISTS
} from 'state/workflows/read-artists/action-types';

import {
  EDIT_MERCH
} from 'state/workflows/edit-merch/action-types';

const editMerchRequest = async (input) => {
  // return await API.graphql(graphqlOperation(updateMerch, { input }));
};

function* editMerchAsync({ payload }) {
  yield editMerchRequest(payload);
  yield put({ type: FETCH_ARTISTS });
};

const sagas = function* watchBeginEditMerchAsync() {
  yield takeEvery(EDIT_MERCH, editMerchAsync);
};

export default sagas;
