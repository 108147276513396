import {
  ADD_MERCH,
  UPDATE_MERCH_DETAILS,
  TOGGLE_ADD_MERCH,
  COMPLETE_NEW_MERCH_CREATION
} from 'state/workflows/add-merch/action-types';

export const addMerch = merch => ({
  type: ADD_MERCH,
  payload: merch
});

export const toggleAddMerch = () => ({
  type: TOGGLE_ADD_MERCH
});

export const updateNewMerchDetails = (key, value) => ({
  type: UPDATE_MERCH_DETAILS,
  payload: { key, value }
});

export const completeNewMerchCreation = (newMerch) => {
  return {
    type: COMPLETE_NEW_MERCH_CREATION,
    payload: newMerch
  };
}
