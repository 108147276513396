const palette = {
  grey: {
    lightest: '#fafafa',
    lighter: '#e5e5e5',
    light: '#c0c0c0',
    base: '#9a9a9a',
    dark: '#757575',
    darker: '#454545',
    darkest: '#151515'
  },
  blue: {
    lightest: 'lightskyblue',
    base: 'lightskyblue'
  }
};

export default (color, shade = 'base') => {
  if (!color) {
    console.error('A color must be included.');
  } else {
    return palette[color][shade];
  }
}
