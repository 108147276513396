import React from 'react';
import { Authenticator } from 'aws-amplify-react';
import { connect } from 'react-redux';
import { Hub } from 'aws-amplify';
import { fetchCurrentUser } from 'state/workflows/fetch-current-user/action-creators';
import { initializeApp } from 'state/workflows/initialization/action-creators';
import Loader from 'design/atoms/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Amplify from 'aws-amplify';
import CreateArtist from 'design/workflows/create-artist';
import EditArtist from 'design/workflows/update-artist';
import RegisterPartner from 'design/workflows/register-partner';
import AddMerch from 'design/workflows/add-merch';
import EditMerch from 'design/workflows/edit-merch';
import DisplayMerch from 'design/workflows/display-merch';
import './App.scss';
import ApplicationHeader from 'design/templates/application-header';
import ApplicationContent from 'design/templates/application-content';
import HomePage from 'design/pages/home';
import PricingPage from 'design/pages/pricing';
import AffiliatePage from 'design/pages/affiliate';
import DashboardPage from 'design/pages/dashboard';
import BrowsePage from 'design/pages/browse';
import config from './aws-exports';

Amplify.configure(config);

const App = class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.initializeApp();
  }

  handleAuth({ channel, payload }) {
    if (channel === 'auth' && ['signIn', 'signOut'].includes(payload.event)) {
      this.props.fetchCurrentUser();
    }
  }

  componentDidMount() {
    Hub.listen('auth', this.handleAuth.bind(this));
  }

  componentWillUnmount() {
    Hub.remove('auth', this.handleAuth.bind(this));
  }

  render() {
    return (
      <div className='application-wrapper'>
        <Router>
          <ApplicationHeader></ApplicationHeader>

          <ApplicationContent>
            { this.props.isInitializing ? (
              <Loader className="dashboard app-loader">
                <FontAwesomeIcon spin icon={ faTshirt } />
              </Loader>
            ) : (
              <Switch>
                <Route path="/browse">
                  <BrowsePage />
                  <Route path="/browse/:merch">
                    <DisplayMerch />
                  </Route>
                </Route>

                <Route path="/pricing">
                  <PricingPage />
                </Route>

                <Route path="/partners">
                  <AffiliatePage />
                </Route>

                <Route path="/partner">
                  <RegisterPartner />
                </Route>

                <Route path="/new-artist">
                  <CreateArtist />
                </Route>

                <Route path="/dashboard/update-artist">
                  <EditArtist />
                </Route>

                <Route path="/dashboard/add-merch">
                  <AddMerch />
                </Route>

                <Route path="/dashboard/edit-merch/:merch">
                  <EditMerch />
                </Route>

                <Route path="/dashboard">
                  <DashboardPage />
                </Route>

                <Route path="/sign-in">
                  { (this.props.currentUser === undefined || this.props.currentUser === null) ? (
                    <Authenticator signUpConfig={{
                      hideAllDefaults: true,
                      signUpFields: [{
                        label: 'Email',
                        key: 'username',
                        required: true,
                        placeholder: 'Email',
                        type: 'email',
                        displayOrder: 1,
                      }, {
                        label: 'Password',
                        key: 'password',
                        required: true,
                        placeholder: 'Password',
                        type: 'password',
                        displayOrder: 2,
                      }]
                    }} />
                  ) : (
                    <Redirect to='/dashboard' />
                  ) }
                </Route>

                <Route path="/:uncaught">
                  <Redirect to='/' />
                </Route>

                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            )}
          </ApplicationContent>
        </Router>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.fetchCurrentUser.currentUser,
    isInitializing: state.initialization.isInitializing
  }
};

const mapDispatchToProps = {
  initializeApp,
  fetchCurrentUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
