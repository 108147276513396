import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

import {
  deleteTag
} from 'graphql/mutations';

import {
  FETCH_TAGS
} from 'state/workflows/read-tags/action-types';

import {
  DELETE_TAG
} from 'state/workflows/delete-tag/action-types';

const deleteTagRequest = async (id) => {
  return await API.graphql(graphqlOperation(deleteTag, { input: { id }}));
};

function* deleteTagAsync({ payload }) {
  yield deleteTagRequest(payload);
  yield put({ type: FETCH_TAGS });
};

const sagas = function* watchDeleteTagAsync() {
  yield takeEvery(DELETE_TAG, deleteTagAsync);
};

export default sagas;
