import { createSelector } from 'reselect'

const _links = (state) => state.fetchUserArtist.currentUserArtist.links || [];
const _contactPoints = (state) => state.fetchUserArtist.currentUserArtist.contactPoints || [];

export const decoratedLinks = createSelector(
  [ _links ],
  (links) => {
    links[0] = links[0] ? links[0] : { label: 'Facebook', url: '' }
    links[1] = links[1] ? links[1] : { label: 'Twitter', url: '' }
    links[2] = links[2] ? links[2] : { label: 'Instagram', url: '' }
    return links;
  }
);

export const decoratedContactPoints = createSelector(
  [ _contactPoints ],
  (contactPoints) => {
    contactPoints[0] = contactPoints[0] ? contactPoints[0] : { label: 'Booking', value: '' }
    return contactPoints;
  }
);
