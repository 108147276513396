import {
  UPDATE_CURRENT_USER,
  CREATE_AFFILIATE_ID,
  ADD_AFFILIATE_REFERRER,
  AFFILIATE_NO_THANKS
} from 'state/workflows/fetch-current-user/action-types';

import {
  UPDATE_USER_AFFILIATE
} from 'state/workflows/initialization/action-types';

const initialState = {
  currentUser: undefined,
  affiliateId: '',
  affiliateReferrer: ''
};

export default function(state = initialState, action) {
  switch (action.type) {

    case UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload
      };
    }

    case CREATE_AFFILIATE_ID: {
      if (!action.payload.id) {
        return state;
      } else {
        return {
          ...state,
          affiliateId: action.payload.id
        };
      }
    }

    case ADD_AFFILIATE_REFERRER: {
      if (!action.payload.id) {
        return state;
      } else {
        return {
          ...state,
          affiliateReferrer: action.payload.id
        };
      }
    }

    case UPDATE_USER_AFFILIATE: {
      return {
        ...state,
        affiliateId: action.payload?.shareableId,
        affiliateReferrer: action.payload?.referrer
      };
    }

    case AFFILIATE_NO_THANKS: {
      if (!action.payload) {
        console.log('AFFILIATE_NO_THANKS 1', action.payload);
        return {
          ...state,
          affiliateId: 'NO_THANKS',
          affiliateReferrer: 'NO_THANKS'
        };
      } else {
        console.log('AFFILIATE_NO_THANKS 2', action.payload);
        return {
          ...state,
          affiliateReferrer: action.payload.referrer,
          affiliateId: action.payload.shareableId
        };
      }
    }

    default:
      return state;
  }
};
