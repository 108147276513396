import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery, select } from 'redux-saga/effects';

import {
  fetchUserArtist
} from 'graphql/queries';

import {
  FETCH_USER_ARTIST,
  UPDATE_USER_ARTIST
} from 'state/workflows/fetch-user-artist/action-types';

const fetchUserArtistRequest = async (input) => {
  return await API.graphql(graphqlOperation(fetchUserArtist, { input }));
};

function* fetchUserArtistAsync() {
  const {
    fetchCurrentUser: { currentUser: { sub }}
  } = yield select();

  try {
    const { attributes } = yield fetchUserArtistRequest({ owner: sub });
    yield put({ type: UPDATE_USER_ARTIST, payload: attributes });
  } catch (e) {
    console.info(e);
    yield put({ type: UPDATE_USER_ARTIST, payload: null });
  }
};

const sagas = function* watchFetchUserArtistAsync() {
  yield takeEvery(FETCH_USER_ARTIST, fetchUserArtistAsync);
};

export default sagas;
