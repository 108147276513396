import React from 'react';
import styled from 'styled-components';

const Content = styled.section`
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

export default class ApplicationContent extends React.Component {
  render() {
    return (
      <Content className='application-content'>
        { this.props.children }
      </Content>
    );
  }
};
