import {
  INITIALIZE_APP,
  INITIALIZATION_COMPLETE
} from 'state/workflows/initialization/action-types';

export const initializeApp = () => ({
  type: INITIALIZE_APP
});

export const initializationComplete = () => ({
  type: INITIALIZATION_COMPLETE
});
