import React from 'react';
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';

// Styles

const StyledTextarea = styled.textarea`
  color: ${ color('grey', 'darkest') };
`;

const StyledLabel = styled.label`
  display: flex;
  margin: ${ whitespace() };

  .label {
    flex: 1 1 33.4%;
    box-sizing: border-box;
    padding: ${ whitespace() } 0;
    margin: ${ whitespace('less') };
    text-align: right;
    color: ${ color('grey', 'light') };
    font-size: ${ fontSize('large') };
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .input {
    display: flex;
    flex: 1 1 50%;
    box-sizing: border-box;

    textarea {
      flex: 1 1 auto;
      box-sizing: border-box;
      padding: ${ whitespace() };
      font-size: 2.4rem;
      border-radius: ${ whitespace('less') };
      border: 2px solid ${ color('grey') };
      color: ${ color('grey', 'dark') };
      outline: none;
      height: 13.5rem;

      &:focus {
        border: 2px solid ${ color('grey', 'darker') };
        color: ${ color('grey', 'darker') };
      }
    }
  }
`;

// Required Properties
// this.props = {
//   label,
//   name,
//   value,
//   title,
//   placeholder,
//   alt,
//   type,
//   disabled,
//   autoFocus,
//   required,
//   onChange
// }

export default class CreateBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  onChange(e) {
    this.setState({ value: e.target.value });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onBlur(e) {
    if (this.props.required && !this.input.value) {
      this.input.setAttribute('placeholder', 'This field is required.');
      this.input.focus();
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  render() {
    return (
      <StyledLabel>
        <span className="label">
          { this.props.label }
        </span>
        <span className="input">
          <StyledTextarea
            className={ this.props.className }
            name={ this.props.name }
            value={ this.state.value }
            title={ this.props.title }
            placeholder={ this.props.placeholder }
            alt={ this.props.alt }
            type={ this.props.type || 'text' }
            disabled={ this.props.disabled || false }
            autoFocus={ this.props.autoFocus || false }
            required={ this.props.required || false }
            onChange={(e) => this.onChange(e) }
            onBlur={(e) => this.onBlur(e) }
          />
        </span>
      </StyledLabel>
    );
  }
};
