import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S3Image } from 'aws-amplify-react';
import { faPlusCircle, faCamera } from '@fortawesome/free-solid-svg-icons';
import { fetchArtists } from 'state/workflows/read-artists/action-creators';
import { updateNewArtistDetails } from 'state/workflows/fetch-user-artist/action-creators';
import { fetchTags } from 'state/workflows/read-tags/action-creators';
import { updateArtist } from 'state/workflows/update-artist/action-creators';
import { createArtist } from 'state/workflows/create-artist/action-creators';
import { decoratedLinks, decoratedContactPoints } from 'state/workflows/fetch-user-artist/selectors';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';
import Input from 'design/atoms/input';
import Textarea from 'design/atoms/textarea';
import Button from 'design/atoms/button';
import CreateTag from 'design/workflows/create-tag';
import StyledParagraph from 'design/atoms/styled-paragraph';
import Toggle from 'design/atoms/toggle';
import Form from 'design/templates/application-form';
import Logo from 'design/atoms/logo';
import Geoloc from 'design/atoms/geoloc';
import StyledHeader from 'design/atoms/styled-header';
import Tooltip from 'design/atoms/tooltip';
import TagList from 'design/atoms/tag-list';
import { FileInput, FileInputWrapper, FileEditWrapper } from 'design/atoms/file-upload';

const UploadLogo = styled(FontAwesomeIcon)`
  display: inline-block;
  line-height: auto;
  color: ${ color('blue') };
`;

const AddAnother = styled.div`
  box-sizing: border-box;
  text-align: right;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: ${ whitespace() };
  font-size: ${ fontSize() };
  margin: 0;
  color: ${ color('blue') };

  &:hover {
    cursor: pointer;
  }
`;

class CreateArtist extends React.Component {
  componentDidMount() {
    this.props.fetchTags();
  }

  addContactPoint() {
    this.props.updateNewArtistDetails({
      contactPoints: [
        ...this.props.contactPoints, {
          label: '',
          value: ''
        }
      ]
    });
  }

  updateContactPoint(e, i) {
    const contactPoints = [ ...this.props.contactPoints ]
    contactPoints[i][e.currentTarget.getAttribute('name')] = e.currentTarget?.value
    this.props.updateNewArtistDetails({ contactPoints });
  }

  addLink() {
    this.props.updateNewArtistDetails({
      links: [
        ...this.props.links,
        {
          label: '',
          url: ''
        }
      ]
    });
  }

  updateLink(e, i) {
    const links = [ ...this.props.links ]
    links[i][e.currentTarget.getAttribute('name')] = e.currentTarget?.value;
    this.props.updateNewArtistDetails({ links });
  }

  updateArtist(registrationStatus) {
    const attributes = {
      owner: this.props.currentUser.sub,
      id: this.props.id,
      name: this.props.name,
      genres: this.props.genres,
      locationLabel: this.props.locationLabel,
      locationCoordinates: this.props.locationCoordinates,
      contactPoints: this.props.contactPoints.filter(c => c.value),
      links: this.props.links.filter(l => l.url),
      registrationStatus
    };

    if (this.props.description) attributes['description'] = this.props.description;
    if (this.props.logoKey) attributes['logoKey'] = this.props.logoKey;

    const isFinalizingCreationProcess = true;
    return this.props.updateArtist(attributes, isFinalizingCreationProcess);
  }

  createArtist() {
    return this.props.createArtist({
      owner: this.props.currentUser.sub,
      name: this.props.name,
      locationLabel: this.props.locationLabel,
      locationCoordinates: this.props.locationCoordinates,
      genres: this.props.genres,
      registrationStatus: 'REGISTERED'
    });
  }

  async handleFileUpload({ target: { files } }) {
    const file = files?.length && files[0];
    const logoKey = file && `images/${uuid()}-${file.name}`;

    if (!file) return;
    try {
      await Storage.put(logoKey, file, { contentType: file.type });
      this.props.updateNewArtistDetails({ logoKey });
    } catch (err) { console.error('error: ', err); }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.registrationStatus === 'REGISTERED') {
      this.updateArtist('COMPLETED');
    } else if (this.props.registrationStatus === null) {
      this.createArtist();
    }
  }

  handleGenreSelection({ currentTarget }) {
    const value = currentTarget.getAttribute('value');
    const genres = [ ...this.props.genres ];

    if (genres.includes(value)) {
      genres.splice(genres.indexOf(value), 1);
      this.props.updateNewArtistDetails({ genres });
    } else if (genres.length <= 4) {
      genres.push(value);
      this.props.updateNewArtistDetails({ genres });
    }
  }

  handleGeolocation(suggestion) {
    if (suggestion) {
      const { label, location } = suggestion;
      this.props.updateNewArtistDetails({
        locationLabel: label,
        locationCoordinates: [ location.lng, location.lat ]
      });
    }
  }

  render() {
    if (this.props.creationProcessComplete || this.props.registrationStatus === 'COMPLETED') {
      return ( <Redirect to='/dashboard' /> );
    } else if (!this.props.affiliateId || !this.props.affiliateReferrer) {
      return ( <Redirect to='/dashboard' /> );
    } else if (!this.props.currentUser) {
      return ( <Redirect to='/sign-in' /> );
    } else {
      return (
        <Form onSubmit={(e) => this.handleSubmit(e)}>

          { console.log(this.props) }

          <StyledHeader>Let's Create Your Artist Profile</StyledHeader>
          <section>
            <StyledParagraph>
              <Logo /> uses information about <strong>your brand and your
              merch</strong> to help potential fans and customers find
              you.<strong> First, let's create your artist profile.</strong>
            </StyledParagraph>

            <Input
              required={ true }
              label='Artist Name'
              placeholder='What is your artist name or stage name?'
              value={ this.props.name }
              onBlur={(e) => {
                this.props.updateNewArtistDetails({ name: e.target.value });
              }}
            />

            <Geoloc
              initialValue={ this.props.locationLabel }
              required={ true }
              className="styled-geo"
              label='Location'
              placeholder='Where are you based out of?'
              onSuggestSelect={(suggestion) => this.handleGeolocation(suggestion)}
            />

            <StyledParagraph>
              Genre helps potential fans and customers to find you based on their
              interests. <strong>Pick at least one (up to five)</strong> from the list below,
              or if we're missing your particular flavor <strong>you can add a
              new genre.</strong>
            </StyledParagraph>

            <TagList>
              { this.props.genreTags?.length &&
                this.props.genreTags.map((tag, i) =>
                <Toggle
                  key={ tag.id }
                  label={ tag.label }
                  name="genre"
                  type="button"
                  className={ this.props.genres.includes(tag.label) ? 'checked' : 'not-checked' }
                  onClick={(e) => this.handleGenreSelection(e)}
                />
              )}

              <Tooltip
                placement="bottom-end"
                trigger="click"
                tooltip={
                  <CreateTag
                    placeholder="What Genre are we missing?"
                    category="Genre"
                    title="Add a New Genre"
                    subtitle="And we definitely don't have what you need?"
                  />
                }
              >
                <FontAwesomeIcon
                  title="Add another genre"
                  icon={ faPlusCircle }
                  size="2x"
                />
              </Tooltip>
            </TagList>

            { this.props.registrationStatus === 'REGISTERED' &&
              <div>
                <StyledParagraph>
                  Almost done! <strong>Just a few more questions,</strong> mostly
                  about online presence. Help us <strong>send potential fans to
                  you.</strong>
                </StyledParagraph>

                <Input
                  autoFocus={ !this.props.links[0]?.url }
                  label='Facebook'
                  name='url'
                  placeholder='What is your Facebook URL?'
                  value={ this.props.links[0]?.url }
                  onBlur={(e) => this.updateLink(e, 0) }
                />

                <Input
                  label='Twitter'
                  name='url'
                  placeholder='What is your Twitter URL?'
                  value={ this.props.links[1]?.url }
                  onBlur={(e) => this.updateLink(e, 1) }
                />

                <Input
                  label='Instagram'
                  name='url'
                  placeholder='What is your Instagram URL?'
                  value={ this.props.links[2]?.url }
                  onBlur={(e) => this.updateLink(e, 2) }
                />

                { this.props.links.length > 3 &&
                  this.props.links.slice(3).map((link, i) =>
                  <div key={ i }>
                    <Input
                      label={ `Link Label (${i})` }
                      name='label'
                      placeholder='Examples: Your Website, SoundCloud, Artistcamp'
                      value={ link.label }
                      onBlur={(e) => this.updateLink(e, i+3)}
                    />

                    <Input
                      label={ `Link URL (${i})` }
                      name='url'
                      placeholder="What's the URL for this link?"
                      value={ link.url }
                      onBlur={(e) => this.updateLink(e, i+3)}
                    />
                  </div>
                )}

                <AddAnother onClick={() => this.addLink() }>
                  Link to another website
                </AddAnother>

                <Input
                  label='Booking'
                  name='value'
                  placeholder='What is your booking email or phone number?'
                  value={ this.props.contactPoints[0]?.value }
                  onBlur={(e) => this.updateContactPoint(e, 0)}
                />

                { this.props.contactPoints.length > 1 &&
                  this.props.contactPoints.slice(1).map((contact, i) =>
                  <div key={ i }>
                    <Input
                      label={ `Contact Name (${i})` }
                      name='label'
                      placeholder='Examples: Management or PR Email'
                      value={ contact.label }
                      onBlur={(e) => this.updateContactPoint(e, i+1)}
                    />

                    <Input
                      label={ `Contact Value (${i})` }
                      name='value'
                      placeholder="What is the email, phone number, etc for this contact?"
                      value={ contact.value }
                      onBlur={(e) => this.updateContactPoint(e, i+1)}
                    />
                  </div>
                )}

                <AddAnother onClick={() => this.addContactPoint() }>
                  Add another Point of Contact
                </AddAnother>

                <Textarea
                  label='Your Aesthetic'
                  placeholder='What do you sound like? What are shows like?'
                  value={ this.props.description || '' }
                  onBlur={(e) => {
                    this.props.updateNewArtistDetails({ description: e.target.value })
                  }}
                />

                { this.props.logoKey ? (
                  <FileEditWrapper>
                    <S3Image imgKey={ this.props.logoKey } />
                    <FileInput
                      type="file"
                      onChange={(e) => this.handleFileUpload(e)}
                    />
                  </FileEditWrapper>
                ) : (
                  <StyledParagraph>
                    <strong>Lastly, upload your logo</strong> so we can
                    present <strong>your merch with your imagery.</strong> For best results, upload something square, with dark text on transparent background.
                    <FileInputWrapper>
                      <UploadLogo
                        title="Upload logo"
                        icon={ faCamera }
                        size="3x"
                      />
                      <FileInput
                        type="file"
                        onChange={(e) => this.handleFileUpload(e)}
                      />
                    </FileInputWrapper>
                  </StyledParagraph>
                )}
              </div>
            }
          </section>

          <footer>
            <Button
              processing={ this.props.isSaving }
              disabled={
                !this.props.name ||
                !this.props.locationLabel ||
                !this.props.locationCoordinates ||
                !this.props.genres?.length
              }
              type='submit'
              label={
                this.props.registrationStatus === 'REGISTERED' ?
                `Finish Artist Profile for ${ this.props.name }` :
                (this.props.isSaving ? 'Registering Artist' : 'Register Artist Profile')
              }
            />
          </footer>
        </Form>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.fetchCurrentUser.currentUser,
    affiliateId: state.fetchCurrentUser.affiliateId,
    affiliateReferrer: state.fetchCurrentUser.affiliateReferrer,
    currentUserArtist: state.fetchUserArtist.currentUserArtist,
    creationProcessComplete: state.createArtist.creationProcessComplete,
    isSaving: state.createArtist.isSaving,
    genreTags: state.readTags.tags.filter(t => t.category === 'Genre').sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (b.label > a.label) {
        return -1;
      } else {
        return 0;
      }
    }),
    id: state.fetchUserArtist.currentUserArtist.id,
    name: state.fetchUserArtist.currentUserArtist.name,
    description: state.fetchUserArtist.currentUserArtist.description,
    locationLabel: state.fetchUserArtist.currentUserArtist.locationLabel,
    locationCoordinates: state.fetchUserArtist.currentUserArtist.locationCoordinates,
    logoKey: state.fetchUserArtist.currentUserArtist.logoKey,
    genres: state.fetchUserArtist.currentUserArtist.genres,
    contactPoints: decoratedContactPoints(state),
    links: decoratedLinks(state),
    registrationStatus: state.fetchUserArtist.currentUserArtist.registrationStatus
  }
};

const mapDispatchToProps = {
  updateArtist,
  createArtist,
  updateNewArtistDetails,
  fetchTags
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateArtist);
