import { createSelector } from 'reselect';

const _merch = (state) => state.fetchUserArtist.currentUserArtist.merch || [];
const _editingMerchItemId = (state) => state.editMerch.editingMerchItemId;

export const editingMerchItem = createSelector(
  [ _merch, _editingMerchItemId ],
  (merch, editingMerchItemId) => {
    console.log('editingMerchItem', merch.find(m => m.id === editingMerchItemId));
    return merch.find(m => m.id === editingMerchItemId);
  }
);
