import styled from 'styled-components';
import color from 'design/subatomics/colors';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  &::after {
    font-size: .85rem;
    font-style: italic;
    display: block;
    position: absolute;
    margin-top: 40vw;
    content: 'Loading stuff...';
    color: ${ color('grey', 'light') };
  }

  svg {
    color: ${ color('grey', 'darkest') };
  }

  @media only screen and (min-width: 0) {
    font-size: 50vw;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 15vw;
  }
`;
