const whitespace = {
  positive: {
    least: '.325rem',
    less: '.75rem',
    base: '1.5rem',
    more: '3.5rem',
    most: '5rem',
  },
  negative: {
    least: '-.325rem',
    less: '-.75rem',
    base: '-1.5rem',
    more: '-3.5rem',
    most: '-5rem',
  }
};

export default (depth = 'base', positiveOrNegative = 'positive') => {
  return whitespace[positiveOrNegative][depth];
}
