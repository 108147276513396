import React from 'react';
import styled from 'styled-components';
import whitespace from 'design/subatomics/whitespace';
import Logo from 'design/atoms/logo';
import Break from 'design/atoms/break';
import StyledParagraph from 'design/atoms/styled-paragraph';
import CallToAction from 'design/atoms/call-to-action';
import StyledHeader from 'design/atoms/styled-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faUser, faSignOutAlt, faSignInAlt, faSearch, faTag, faTshirt, faListAlt, faUsers } from '@fortawesome/free-solid-svg-icons'
import color from 'design/subatomics/colors';

const MarketingPage = styled.article`
  p {
    width: 100%;
    line-height: 2.5rem;
    font-size: 1.15rem;

    padding-top: ${ whitespace('less') };
    padding-bottom: ${ whitespace('less') };

    .brand-logo {
      font-size: 1.85rem;
    }
  }

  svg {
    position: fixed;
    color: ${ color('blue') };
  }

  @media only screen and (min-width: 0) {
    margin: ${ whitespace('less') };

    svg {
      opacity: .2;
      top: calc(${ whitespace() } + ${ whitespace('more') });
      right: ${ whitespace('less') };
      font-size: 35vw;
    }
  }

  @media only screen and (min-width: 1200px) {
    margin: ${ whitespace('more') } ${ whitespace('most') };
    padding: 0 ${ whitespace('more') };
    margin-left: 35%;

    svg {
      opacity: .35;
      top: calc(110px + ${ whitespace('most') });
      left: calc(17.2% - 10vw);
      font-size: 20vw;
    }
  }

`;

export default class AffiliatePage extends React.Component {
  componentWillUnmount() {
    document.querySelector('.application-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  render() {
    return (
      <div>
        <StyledHeader>Own What You Help Build</StyledHeader>
        <MarketingPage>
          <FontAwesomeIcon icon={ faUsers } />

          <StyledParagraph>
            At <Logo />, we understand that we're nothing without a community of artists and fans around us. We also understand we can't build that community by ourselves. That's why we don't just ask for your help, we want to compensate you for it.
          </StyledParagraph>

          <StyledParagraph>
            Before we even launched our first app, we decided to set aside 10% equity for those of you who help us build the community. It's easy, when you join you're asked two questions, 1) who referred you so they get credit, and 2) if you want to create a Partner Id, so you can get credit.
          </StyledParagraph>

          <StyledParagraph>
            We track how impactful each user is and the more they engage with us, the more credit you get. Your equity doesn't only apply to <Logo /> We have multiple apps in the roadmap and they're all related in various ways. Your cut of that 10% equity share applies to all of our products.
          </StyledParagraph>

          <StyledParagraph>
            There's a lot of details still to be worked out, but at some point after we reach profitability we'll either start paying dividend or look to get acquired. If you own a piece, you get paid when we get paid.
          </StyledParagraph>

          <CallToAction to="/partner">
           Get a PartnerId Now
          </CallToAction>
        </MarketingPage>
      </div>
    );
  }
};
