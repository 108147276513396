import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

import {
  // deleteMerch
} from 'graphql/mutations';

import {
  FETCH_ARTISTS
} from 'state/workflows/read-artists/action-types';

import {
  DELETE_MERCH
} from 'state/workflows/delete-merch/action-types';

const deleteMerchRequest = async (id) => {
  // return await API.graphql(graphqlOperation(deleteMerch, { input: { id }}));
};

function* deleteMerchAsync({ payload }) {
  yield deleteMerchRequest(payload);
  yield put({ type: FETCH_ARTISTS });
};

const sagas = function* watchDeleteMerchAsync() {
  yield takeEvery(DELETE_MERCH, deleteMerchAsync);
};

export default sagas;
