import {
  FETCH_USER_ARTIST,
  UPDATE_NEW_ARTIST_DETAILS
} from 'state/workflows/fetch-user-artist/action-types';

export const fetchUserArtist = () => ({
  type: FETCH_USER_ARTIST
});

export const updateNewArtistDetails = (updates) => ({
  type: UPDATE_NEW_ARTIST_DETAILS,
  payload: updates
});
