import styled from 'styled-components'
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';

export default styled.p`
  color: ${ color('grey', 'darker') };
  position: relative;

  a {
    color: ${ color('blue') };
    text-decoration: none;
  }

  &.update-logo {
    font-size: 1.15rem;
    margin-left: 200px!important;
    text-align: center;
    width: auto;
  }

  span {
    color: ${ color('grey', 'darker') };
    font-weight: 700;
  }

  strong {
    color: ${ color('grey', 'darker') };
  }

  @media only screen and (min-width: 0) {
    width: 100%;
    font-size: 1rem;
    line-height: 2rem;
    padding: 0 ${ whitespace('least') };

    span {
      font-size: 1em;
    }

    strong {
      font-weight: normal;
      font-size: 1em;

      &:first-of-type {
        font-size: 1em;
      }

      &:last-of-type {
        font-size: 1em;
      }
    }

    .brand-logo {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    width: 55%;
    font-size: 1.5rem;
    line-height: 5rem;
    padding: ${ whitespace() } 0;

    span {
      font-size: 1.5em;
    }

    strong {
      font-size: 1.35em;

      &:first-of-type {
        font-size: 1.65em;
      }

      &:last-of-type {
        font-size: 2.35em;
      }
    }

    .brand-logo {
      font-size: 3rem;
    }
  }

`;
