import React from 'react';
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';
import Geosuggest from 'react-geosuggest';

// Styles

const StyledLabel = styled.label`
  display: flex;
  align-items: center;

  .label {
    flex: 1 1 33.4%;
    box-sizing: border-box;
    padding: ${ whitespace() } 0;
    margin: ${ whitespace('less') };
    text-align: right;
    color: ${ color('grey', 'light') };
    font-size: ${ fontSize('large') };
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .input {
    display: flex;
    flex: 1 1 100%;
    box-sizing: border-box;

    .styled-geo {
      width: 100%;
      position: relative;

      .geosuggest__input-wrapper {
        width: 100%;
      }

      .geosuggest__suggests-wrapper ul {
        position: absolute;
        margin: 0;
        padding: 0;
        z-index: 100;
        &.geosuggest__suggests--hidden {
          display: none;
        }

        li {
          list-style: none;
          position: relative;
          top: -3px;
          left: ${ whitespace('less') };
          padding: ${ whitespace('less') } ${ whitespace('most') } ${ whitespace('less') } ${ whitespace('less') };
          background: ${ color('grey', 'lightest') };
          border-left: 1px solid ${ color('grey', 'darkest') };
          border-right: 1px solid ${ color('grey', 'darkest') };
          background: ${ color('grey', 'lightest') };

          &.geosuggest__item--active {
            background: ${ color('grey', 'lighter') };
          }

          &:first-child {
            border-top: 1px solid ${ color('grey', 'darkest') };
          }
          &:last-child {
            border-bottom: 1px solid ${ color('grey', 'darkest') };
          }
        }
      }

      input {
        width: 100%;
        flex: 1 1 100%;
        box-sizing: border-box;
        border: 1px solid ${ color('grey') };
        color: ${ color('grey', 'dark') };
        outline: none;

        &:focus {
          border: 1px solid ${ color('grey', 'darker') };
          color: ${ color('grey', 'darker') };
        }
      }
    }
  }

  @media only screen and (min-width: 0) {
    margin: ${ whitespace('less') };

    .input {
      padding: 0;
    }

    input {
      padding: ${ whitespace('less') };
      font-size: 1rem;
      border-radius: ${ whitespace('least') };
    }
  }

  @media only screen and (min-width: 1200px) {
    margin: ${ whitespace() };

    .input {
      padding: ${ whitespace('less') } 0;
    }

    input {
      padding: ${ whitespace() };
      font-size: 2.4rem;
      border-radius: ${ whitespace('least') };
    }

  }

`;

export default class GeoLoc extends React.Component {
  onBlur(e) {
    if (this.props.required && !this.geosuggest.input.input.value) {
      this.geosuggest.input.input.setAttribute('placeholder', 'This field is required.');
      this.geosuggest.input.input.focus();
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  render() {
    return (
      <StyledLabel>
        { this.props.label && (
          <span className="label">
            { this.props.label }
            { this.props.required && <sup title="Required">*</sup> }
          </span>
        )}
        <span className="input">
          <Geosuggest
            className={ `styled-geo ${ this.props.context }` }
            types={[ "(cities)" ]}
            initialValue={ this.props.initialValue }
            ref={ref => this.geosuggest = ref }
            onSuggestSelect={ this.props.onSuggestSelect }
            placeholder={ this.props.placeholder }
            onBlur={(e) => { this.onBlur(e) }}
            title={ this.props.title }
          />
        </span>
      </StyledLabel>
    );
  }
};
