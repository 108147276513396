import {
  CREATE_ARTIST,
  TOGGLE_NEW_GENRE
} from 'state/workflows/create-artist/action-types';

export const createArtist = artist => ({
  type: CREATE_ARTIST,
  payload: artist
});

export const toggleNewGenre = () => ({
  type: TOGGLE_NEW_GENRE
});
