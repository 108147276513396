import { API, graphqlOperation } from 'aws-amplify';
import { select, put, takeEvery } from 'redux-saga/effects'

import {
  UPDATE_EDITING_MERCH_DETAILS
} from 'state/workflows/edit-merch/action-types';

import {
  UPDATE_USER_ARTIST,
  FETCH_USER_ARTIST
} from 'state/workflows/fetch-user-artist/action-types';

import {
  UPDATE_MERCH_DETAILS,
  COMPLETE_NEW_MERCH_CREATION,
  RESET_NEW_MERCH_CREATION
} from 'state/workflows/add-merch/action-types';

import {
  updateArtist
} from 'graphql/mutations';

import {
  CREATE_ARTIST_COMPLETE
} from 'state/workflows/create-artist/action-types';

import {
  UPDATE_ARTIST,
  UPDATE_NEW_ARTIST_DETAILS
} from 'state/workflows/update-artist/action-types';

const updateArtistRequest = async (input) => {
  return await API.graphql(graphqlOperation(updateArtist, { input }));
};

function* updateArtistAsync({ payload, isFinalizingCreationProcess }) {
  try {
    yield updateArtistRequest(payload);
    yield put({ type: CREATE_ARTIST_COMPLETE });
  } catch (e) {
    console.error(e);
  }
};

function* completeNewMerchCreation({ payload }) {
  const {
    fetchUserArtist: { currentUserArtist },
    fetchCurrentUser: { currentUser: { sub }}
  } = yield select();

  try {
    const { data: { updateArtist: { data }}} = yield updateArtistRequest({
      ...currentUserArtist,
      owner: sub,
      merch: [...currentUserArtist.merch, payload]
    });
    yield put({
      type: UPDATE_MERCH_DETAILS,
      payload: {
        key: 'status',
        value: 'SAVED'
      }
    });
    yield put({
      type: UPDATE_USER_ARTIST,
      payload: data
    });
    yield put({ type: RESET_NEW_MERCH_CREATION });
  } catch (e) {
    console.error(e);
  }
};

function* autoSave({ payload }) {
  const {
    addMerch: {
      newMerchItem
    },
    fetchCurrentUser: {
      currentUser
    },
    fetchUserArtist: {
      currentUserArtist
    }
  } = yield select();
  if (currentUserArtist.registrationStatus) {
    try {
      yield updateArtistRequest({
        owner: currentUser.sub,
        id: currentUserArtist.id,
        name: currentUserArtist.name,
        genres: currentUserArtist.genres,
        locationLabel: currentUserArtist.locationLabel,
        locationCoordinates: currentUserArtist.locationCoordinates,
        description: currentUserArtist.description,
        logoKey: currentUserArtist.logoKey,
        contactPoints: currentUserArtist.contactPoints?.filter(c => c.value && c.label),
        links: currentUserArtist.links?.filter(l => l.url && l.label),
        registrationStatus: currentUserArtist.registrationStatus,
        merch: currentUserArtist.merch
      });
    } catch (e) {
      console.error(e);
    }
  }
};

const sagas = function* watchBeginUpdateArtistAsync() {
  yield takeEvery(COMPLETE_NEW_MERCH_CREATION, completeNewMerchCreation);
  yield takeEvery(UPDATE_ARTIST, updateArtistAsync);
  yield takeEvery(UPDATE_NEW_ARTIST_DETAILS, autoSave);
  yield takeEvery(UPDATE_EDITING_MERCH_DETAILS, autoSave);

  // yield takeEvery(UPDATE_MERCH_DETAILS, autoSave);
};

export default sagas;
