import {
  TOGGLE_ADD_MERCH,
  UPDATE_MERCH_DETAILS,
  COMPLETE_NEW_MERCH_CREATION,
  RESET_NEW_MERCH_CREATION
} from 'state/workflows/add-merch/action-types';

const initialState = {
  addMerchMode: false,
  newMerchItem: {
    id: null,
    name: '',
    description: '',
    price: '',
    url: '',
    categories: [],
    imageKey: '',
    status: 'UNINITIATED'
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COMPLETE_NEW_MERCH_CREATION: {
      return state;
    }

    case RESET_NEW_MERCH_CREATION: {
      return {
        ...state,
        newMerchItem: {
          id: null,
          name: '',
          description: '',
          price: '',
          url: '',
          categories: [],
          imageKey: '',
          status: 'UNINITIATED'
        }
      };
    }

    case TOGGLE_ADD_MERCH: {
      return {
        ...state,
        addMerchMode: !state.addMerchMode
      };
    }

    case UPDATE_MERCH_DETAILS: {
      let newState = { ...state };
      const { key, value } = action.payload;
      newState.newMerchItem[key] = value;
      return newState;
    }

    default:
      return state;
  }
};
