import styled from 'styled-components'
import whitespace from 'design/subatomics/whitespace';
import color from 'design/subatomics/colors';

export const FileInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const FileInputWrapper = styled.label`
  text-align: center;
  display: inline-block;
  position: relative;
  padding: ${ whitespace('less') };
  line-height: ${ whitespace() };

  img {
    border-radius: ${ whitespace('least') };
    box-shadow: 1px 1px 2px ${ color('grey', 'dark') };
  }

  &:hover {
    cursor: pointer;
  }
`;

export const FileEditWrapper = styled(FileInputWrapper)`
  display: block;
  margin: ${ whitespace('more') } auto 0;
  color: ${ color('blue') };

  img {
    max-width: 50vw;    
  }

  svg {
    position: relative;
    top: -1px;
    font-size: .85rem;
    margin-right: ${ whitespace('least') };
  }
`;
