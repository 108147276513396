import { decoratedLinks, decoratedContactPoints } from 'state/workflows/fetch-user-artist/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { addMerch } from 'state/workflows/add-merch/action-creators';
import Input from 'design/atoms/input';
import Button from 'design/atoms/button';
import { fetchTags } from 'state/workflows/read-tags/action-creators';
import Textarea from 'design/atoms/textarea';
import StyledParagraph from 'design/atoms/styled-paragraph';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';
import styled from 'styled-components'
import Toggle from 'design/atoms/toggle';
import { PhotoPicker, S3Image } from 'aws-amplify-react';
import uuid from 'uuid/v4';
import { Storage } from 'aws-amplify'
import { toggleAddMerch, completeNewMerchCreation } from 'state/workflows/add-merch/action-creators';
import CreateTag from 'design/workflows/create-tag';
import StyledHeader from 'design/atoms/styled-header';
import { Redirect, Link } from "react-router-dom";
import Form from 'design/templates/application-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlusCircle, faCamera, faTshirt, faChevronRight, faHandPointLeft, faHandPointDown } from '@fortawesome/free-solid-svg-icons';
import { updateNewArtistDetails } from 'state/workflows/fetch-user-artist/action-creators';
import { updateNewMerchDetails } from 'state/workflows/add-merch/action-creators';
import { updateArtist } from 'state/workflows/update-artist/action-creators';
import { FileInput, FileInputWrapper, FileEditWrapper } from 'design/atoms/file-upload';
import TagList from 'design/atoms/tag-list';
import Tooltip from 'design/atoms/tooltip';

const UploadLogo = styled(FontAwesomeIcon)`
  display: inline-block;
  line-height: auto;
  color: ${ color('blue') };
`;


class AddMerch extends React.Component {
  handleSubmit(e) {
    e.preventDefault();
    const merch = {
      ...this.props.newMerchItem,
      status: 'SAVED'
    };

    if (!merch.description) delete merch['description'];
    if (!merch.id) delete merch['id'];

    return this.props.completeNewMerchCreation(merch);
  }

  handleCategorySelection({ currentTarget }) {
    const value = currentTarget.getAttribute('value');
    const categories = [ ...(this.props.newMerchItem.categories || []) ];

    if (categories.includes(value)) {
      categories.splice(categories.indexOf(value), 1);
      this.props.updateNewMerchDetails('categories', categories);
    } else if (categories.length <= 4) {
      categories.push(value);
      this.props.updateNewMerchDetails('categories', categories);
    }
  }

  async handleFile({ target: { files } }) {
    const file = files?.length && files[0];
    const key = file && `images/${uuid()}-${file.name}`;

    if (!file) return;
    try {
      await Storage.put(key, file, { contentType: file.type });
      this.props.updateNewMerchDetails('imageKey', key);
    } catch (err) { console.error('error: ', err); }
  }

  render() {
    if (!this.props.currentUserArtist) {
      return ( <Redirect to="/sign-in" /> );
    } else if (this.props.newMerchItem.status === 'SAVED') {
      return ( <Redirect to="/dashboard" /> );
    } else {
      return (
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <StyledHeader>
            <Link to="/dashboard">{ this.props.currentUserArtist.name } Dashboard</Link>
            <FontAwesomeIcon icon={ faChevronRight } />
            Add Merch
          </StyledHeader>

          <section>
            <StyledParagraph>
              <strong>What are you selling?</strong> Select one or more categories
              that <strong>best describes your merch. </strong> If we don't have
              it, <strong>add a new category.</strong>
            </StyledParagraph>

            <TagList>
              { this.props.categoryTags?.length &&
                this.props.categoryTags.map((tag, i) =>
                <Toggle
                  key={ tag.id }
                  label={ tag.label }
                  name="category"
                  type="button"
                  className={ this.props.newMerchItem.categories?.includes(tag.label) ? 'checked' : 'not-checked' }
                  onClick={(e) => this.handleCategorySelection(e)}
                />
              )}

              <Tooltip
                placement="bottom-end"
                trigger="click"
                tooltip={
                  <CreateTag
                    placeholder="What Category are we missing?"
                    category="Merch Category"
                    title="Add a Merch Category"
                    subtitle="And we definitely don't have what you need?"
                  />
                }
              >
                <FontAwesomeIcon
                  title="Add another Merch Category"
                  icon={ faPlusCircle }
                  size="2x"
                />
              </Tooltip>
            </TagList>

            <StyledParagraph>
              <strong>Now for a bit more detail.</strong> This is your chance
              to really sell it. <strong>Use the good words.</strong>
            </StyledParagraph>

            <Input
              required={ true }
              label='Product Name'
              placeholder='What do you call it?'
              value={ this.props.newMerchItem.name }
              onBlur={(e) => {
                this.props.updateNewMerchDetails('name', e.target.value);
              }}
            />

            <Input
              required={ true }
              label='Price'
              placeholder='How much does it cost?'
              value={ this.props.newMerchItem.price }
              onBlur={(e) => {
                this.props.updateNewMerchDetails('price', e.target.value);
              }}
            />

            <Textarea
              label='Description'
              placeholder='Are there options; is it Limited Edition?'
              value={ this.props.newMerchItem.description }
              onBlur={(e) => { this.props.updateNewMerchDetails('description', e.target.value) }}
            />

            <Input
              required={ true }
              label='URL'
              placeholder='Where can I buy it online?'
              value={ this.props.newMerchItem.url }
              onBlur={(e) => { this.props.updateNewMerchDetails('url', e.target.value)}}
            />

            { this.props.newMerchItem.imageKey ? (
              <FileEditWrapper>
                <S3Image imgKey={ this.props.newMerchItem.imageKey } />
                <FileInput
                  type="file"
                  onChange={(e) => this.handleFile(e)}
                />
                <FontAwesomeIcon
                  title="Edit Merch Image"
                  icon={ faPencilAlt }
                />
                Click to edit merch image
              </FileEditWrapper>
            ) : (
              <StyledParagraph>
                <strong>Lastly, upload an image</strong> of your merch. You only get
                one, so <strong>make it count.</strong>
                <FileInputWrapper>
                  <UploadLogo
                    title="Upload logo"
                    icon={ faCamera }
                    size="3x"
                  />
                  <FileInput
                    type="file"
                    onChange={(e) => this.handleFile(e)}
                  />
                </FileInputWrapper>
              </StyledParagraph>
            )}
          </section>
          <footer>
            <Button
              processing={ this.props.isSaving }
              disabled={
                !this.props.newMerchItem.name ||
                !this.props.newMerchItem.categories?.length ||
                !this.props.newMerchItem.price ||
                !this.props.newMerchItem.imageKey ||
                !this.props.newMerchItem.url
              }
              type='submit'
              label={
                this.props.registrationStatus === 'REGISTERED' ?
                `Finish Merch` :
                (this.props.isSaving ? 'Adding Merch' : 'Add Merch')
              }
            />
          </footer>
        </Form>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    state,
    newMerchItem: state.addMerch.newMerchItem,
    tags: state.readTags.tags,
    currentUser: state.fetchCurrentUser.currentUser,
    currentUserArtist: state.fetchUserArtist.currentUserArtist,
    categoryTags: state.readTags.tags.filter(t => t.category === 'Merch Category').sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (b.label > a.label) {
        return -1;
      } else {
        return 0;
      }
    })
  }
};

const mapDispatchToProps = {
  addMerch,
  toggleAddMerch,
  updateNewArtistDetails,
  updateNewMerchDetails,
  updateArtist,
  completeNewMerchCreation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMerch);
