import {
  ERRORED_SAVE,
  TOGGLE_NEW_GENRE,
  CREATE_ARTIST,
  ARTIST_CREATED,
  CREATE_ARTIST_COMPLETE
} from 'state/workflows/create-artist/action-types';

import {
  LIST_ARTISTS
} from 'state/workflows/read-artists/action-types';

import {
  UPDATE_ARTIST
} from 'state/workflows/update-artist/action-types';

const initialState = {
  registered: false,
  isSaving: null,
  artistSaved: false,
  artistSaveError: null,
  artistSaveErrored: false,
  displayNewGenreInput: false,
  creationProcessComplete: false
};

export default function(state = initialState, action) {
  switch (action.type) {

    case CREATE_ARTIST_COMPLETE: {
      return {
        ...state,
        creationProcessComplete: true
      };
    }

    case CREATE_ARTIST: {
      return {
        ...state,
        isSaving: true
      };
    }

    case ERRORED_SAVE: {
      return {
        ...state,
        isSaving: false
      };
    }

    case ARTIST_CREATED: {
      return {
        ...state,
        isSaving: false
      };
    }

    case TOGGLE_NEW_GENRE: {
      return {
        ...state,
        displayNewGenreInput: !state.displayNewGenreInput
      };
    }

    default:
      return state;
  }
};
