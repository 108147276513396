import { Auth, API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

import {
  fetchUserArtist,
  fetchUserAffiliate
} from 'graphql/queries';

import {
  listTags
} from 'graphql/queries';

import {
  FETCH_TAGS,
  LIST_TAGS
} from 'state/workflows/read-tags/action-types';


import {
  UPDATE_CURRENT_USER
} from 'state/workflows/fetch-current-user/action-types';

import {
  UPDATE_USER_ARTIST
} from 'state/workflows/fetch-user-artist/action-types';

import {
  INITIALIZE_APP,
  INITIALIZATION_COMPLETE,
  UPDATE_USER_AFFILIATE
} from 'state/workflows/initialization/action-types';

const fetchUserArtistRequest = async (input) => {
  console.info('Requesting User Artist');
  return await API.graphql(graphqlOperation(fetchUserArtist, { input }));
};

const fetchUserAffiliateRequest = async (input) => {
  console.info('Requesting User Affiliate');
  return await API.graphql(graphqlOperation(fetchUserAffiliate, { input }));
};

const fetchCurrentUserRequest = async () => {
  console.info('Requesting Current User');
  return await Auth.currentAuthenticatedUser();
};

const fetchTagsRequest = async () => {
  console.info('Requesting Tags');
  return await API.graphql({
    query: listTags,
    variables: { limit: 2000 },
    authMode: 'API_KEY'
  });
};

function* initializeApp() {
  console.log('initializeApp');
  try {
    const { data: { listTags: { items } } } = yield fetchTagsRequest();
    console.log('initializeApp', items);
    yield put({ type: LIST_TAGS, payload: items });

    const currentUser = yield fetchCurrentUserRequest();
    yield put({ type: UPDATE_CURRENT_USER, payload: currentUser.attributes });

    const affiliate = yield fetchUserAffiliateRequest({ owner: currentUser.attributes.sub });
    yield put({ type: UPDATE_USER_AFFILIATE, payload: affiliate.data.fetchUserAffiliate.data });

    const { data: { fetchUserArtist } } = yield fetchUserArtistRequest({ owner: currentUser.attributes.sub });
    yield put({ type: UPDATE_USER_ARTIST, payload: fetchUserArtist.data });
  } catch (e) {
    console.error(e);
  }

  yield put({ type: INITIALIZATION_COMPLETE });
};

const sagas = function* watchInitializationSagas() {
  yield takeEvery(INITIALIZE_APP, initializeApp);
};

export default sagas;
