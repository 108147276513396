import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

// import {
//   createMerch
// } from 'graphql/mutations';

import {
  ADD_MERCH
} from 'state/workflows/add-merch/action-types';

import {
  FETCH_ARTISTS
} from 'state/workflows/read-artists/action-types';

const addMerchRequest = async (input) => {
  // return await API.graphql(graphqlOperation(createMerch, { input }));
};

function* addMerchAsync({ payload }) {
  yield addMerchRequest(payload);
  yield put({ type: FETCH_ARTISTS });
};

const sagas = function* watchAddMerchAsync() {
  yield takeEvery(ADD_MERCH, addMerchAsync);
};

export default sagas;
