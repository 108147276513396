import React from 'react';
import styled from 'styled-components'
import { Link } from "react-router-dom";
import color from 'design/subatomics/colors';

const Logo = styled.span`
  display: inline-flex;
  font-family: 'Amatic SC', cursive;
  font-size: 2.5rem;
  font-weight: 100;
  text-decoration: none;
  color: ${ color('grey', 'darker') };
  letter-spacing: -2px;

  &:hover {
    cursor: pointer;
    color: ${ color('blue') };
  }

  &[aria-current='page'] {
    cursor: default;
    color: ${ color('blue') };
  }

`;

export default () => {
  return (
    <Logo className='brand-logo'>
      MerchGa.me
    </Logo>
  );
};
