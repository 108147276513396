import { API } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

import {
  listTags
} from 'graphql/queries';

import {
  FETCH_TAGS,
  LIST_TAGS
} from 'state/workflows/read-tags/action-types';

const fetchTagsRequest = async () => {
  return await API.graphql({
    query: listTags,
    variables: { limit: 2000 }
  });

};

function* fetchTagsAsync() {
  try {
    const { data: { listTags: { items } } } = yield fetchTagsRequest();
    yield put({ type: LIST_TAGS, payload: items });
  } catch (response) {
    console.error(response);
  }
};

const sagas = function* watchFetchTagsAsync() {
  yield takeEvery(FETCH_TAGS, fetchTagsAsync);
};

export default sagas;
