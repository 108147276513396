import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

import {
  createArtist
} from 'graphql/mutations';

import {
  CREATE_ARTIST,
  ERRORED_SAVE,
  ARTIST_CREATED
} from 'state/workflows/create-artist/action-types';

const createArtistRequest = async (input) => {
  return await API.graphql(graphqlOperation(createArtist, { input }));
};

function* createArtistAsync({ payload }) {
  try {
    const { data: { createArtist }} = yield createArtistRequest(payload);
    yield put({ type: ARTIST_CREATED, payload: createArtist.data });
  } catch ({ errors }) {
    console.error(errors);
    yield put({ type: ERRORED_SAVE });
  }
};

const sagas = function* watchCreateArtistSagas() {
  yield takeEvery(CREATE_ARTIST, createArtistAsync);
};

export default sagas;
