import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import fetchCurrentUser from 'state/workflows/fetch-current-user/reducers';
import fetchUserArtist from 'state/workflows/fetch-user-artist/reducers';
import createArtist from 'state/workflows/create-artist/reducers';
import addMerch from 'state/workflows/add-merch/reducers';
import readTags from 'state/workflows/read-tags/reducers';
import readArtists from 'state/workflows/read-artists/reducers';
import readMerch from 'state/workflows/read-merch/reducers';
import editMerch from 'state/workflows/edit-merch/reducers';
import updateArtist from 'state/workflows/update-artist/reducers';
import initialization from 'state/workflows/initialization/reducers';
import fetchCurrentUserSagas from 'state/workflows/fetch-current-user/sagas';
import fetchUserArtistSagas from 'state/workflows/fetch-user-artist/sagas';
import createArtistSagas from 'state/workflows/create-artist/sagas';
import readArtistsSagas from 'state/workflows/read-artists/sagas';
import updateArtistSagas from 'state/workflows/update-artist/sagas';
import createTagSagas from 'state/workflows/create-tag/sagas';
import addMerchSagas from 'state/workflows/add-merch/sagas';
import readMerchSagas from 'state/workflows/read-merch/sagas';
import readTagsSagas from 'state/workflows/read-tags/sagas';
import initializationSagas from 'state/workflows/initialization/sagas';

import editMerchSagas from 'state/workflows/edit-merch/sagas';
import editTagSagas from 'state/workflows/edit-tag/sagas';

import deleteMerchSagas from 'state/workflows/delete-merch/sagas';
import deleteTagSagas from 'state/workflows/delete-tag/sagas';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  initialization,
  fetchUserArtist,
  fetchCurrentUser,
  createArtist,
  readArtists,
  readMerch,
  readTags,
  updateArtist,
  addMerch,
  editMerch
});

const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

function* rootSaga() {
  yield all([
    initializationSagas(),
    fetchCurrentUserSagas(),
    fetchUserArtistSagas(),
    createArtistSagas(),
    readArtistsSagas(),
    updateArtistSagas(),
    addMerchSagas(),
    readMerchSagas(),
    deleteMerchSagas(),
    editMerchSagas(),
    createTagSagas(),
    editTagSagas(),
    readTagsSagas(),
    deleteTagSagas()
  ]);
};

sagaMiddleware.run(rootSaga);

export default store;
