import {
  FETCH_CURRENT_USER,
  CREATE_AFFILIATE_ID,
  ADD_AFFILIATE_REFERRER,
  AFFILIATE_NO_THANKS
} from 'state/workflows/fetch-current-user/action-types';

export const fetchCurrentUser = () => ({
  type: FETCH_CURRENT_USER
});

export const createAffiliateId = (id) => ({
  type: CREATE_AFFILIATE_ID,
  payload: {
    key: 'shareableId',
    id
  }
});

export const affiliateNoThanks = (payload) => ({
  type: AFFILIATE_NO_THANKS,
  payload
});

export const addAffiliateReferrer = (id) => ({
  type: ADD_AFFILIATE_REFERRER,
  payload: {
    key: 'referrer',
    id
  }
});
