import {
  TOGGLE_EDIT_MERCH,
  UPDATE_MERCH_ITEM_EDITING_ID,
  UPDATE_EDITING_MERCH_DETAILS
} from 'state/workflows/edit-merch/action-types';

const initialState = {
  editMerchMode: false,
  editingMerchItemId: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MERCH_ITEM_EDITING_ID: {
      return {
        ...state,
        editingMerchItemId: action.payload
      };
    }

    case TOGGLE_EDIT_MERCH: {
      return {
        ...state,
        editMerchMode: state.editMerchMode === action.payload ? undefined : action.payload
      };
    }

    default:
      return state;
  }
};
