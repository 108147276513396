import { Auth, API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery, select } from 'redux-saga/effects';

import {
  updateAffiliateDetails
} from 'graphql/mutations';

import {
  FETCH_CURRENT_USER,
  UPDATE_CURRENT_USER,
  CREATE_AFFILIATE_ID,
  ADD_AFFILIATE_REFERRER,
  AFFILIATE_NO_THANKS
} from 'state/workflows/fetch-current-user/action-types';

const fetchCurrentUserRequest = async () => {
  return await Auth.currentAuthenticatedUser();
};

const updateAffiliateRequest = async (input) => {
  return await API.graphql(graphqlOperation(updateAffiliateDetails, { input }));
};

function* updateAffiliateAsync({ payload: { key, id }}) {
  if (!id) {
    return;
  }
  
  const {
    fetchCurrentUser: { currentUser: { sub }}
  } = yield select();

  try {
    const input = { owner: sub };
    input[key] = id;

    const response = yield updateAffiliateRequest(input);
  } catch (e) {
    console.info(e);
  }
};

function* noThanksAffiliateAsync(action) {
  const {
    fetchCurrentUser: { currentUser: { sub }}
  } = yield select();

  try {
    let input;

    if (action.payload) {
      input = {
        ...action.payload,
        owner: sub,
      };
    } else {
      input = {
        owner: sub,
        referrer: 'NO_THANKS',
        shareableId: 'NO_THANKS'
      };
    }

    yield updateAffiliateRequest(input);
  } catch (e) {
    console.info(e);
  }
};

function* fetchCurrentUserAsync() {
  try {
    const { attributes } = yield fetchCurrentUserRequest();
    yield put({ type: UPDATE_CURRENT_USER, payload: attributes });
  } catch (e) {
    console.info(e);
    yield put({ type: UPDATE_CURRENT_USER, payload: null });
  }
};

const sagas = function* watchFetchCurrentUserAsync() {
  yield takeEvery(FETCH_CURRENT_USER, fetchCurrentUserAsync);
  yield takeEvery(CREATE_AFFILIATE_ID, updateAffiliateAsync);
  yield takeEvery(ADD_AFFILIATE_REFERRER, updateAffiliateAsync);
  yield takeEvery(AFFILIATE_NO_THANKS, noThanksAffiliateAsync);
};

export default sagas;
