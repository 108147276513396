import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';
import { updateTag } from 'graphql/mutations';
import { FETCH_TAGS } from 'state/workflows/read-tags/action-types';
import { EDIT_TAG } from 'state/workflows/edit-tag/action-types';

const editTagRequest = async (input) => {
  return await API.graphql(graphqlOperation(updateTag, { input }));
};

function* editTagAsync({ payload }) {
  yield editTagRequest(payload);
  yield put({ type: FETCH_TAGS });
};

const sagas = function* watchEditTagAsync() {
  yield takeEvery(EDIT_TAG, editTagAsync);
};

export default sagas;
