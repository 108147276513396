import styled from 'styled-components'
import { Link } from "react-router-dom";
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';

export default styled(Link)`
  background: ${ color('blue') };
  color: #fff;
  border-style: solid;
  border-color: ${ color('grey', 'darker') };
  text-align: center;
  margin: 0 auto;
  font-weight: 100;
  letter-spacing: 2px;
  text-decoration:none;
  display: inline-block;
  box-sizing: border-box;
  text-transform: uppercase;

  svg {
    font-size: 1.15em;
    color: #fff;
  }

  .brand-logo {
    font-size: 1.15em;
    margin-top: -.15em;
    color: #fff!important;
    text-shadow: none!important;
  }

  &:hover {
    background: #fff;
    color: ${ color('blue') };
    border-color: ${ color('grey', 'light') };

    .brand-logo {
      color: ${ color('blue') }!important;
    }

    svg {
      color: ${ color('blue') };
    }
  }

  @media only screen and (min-width: 0) {
    display: block;
    margin: ${ whitespace('least') };
    border-radius: ${ whitespace('least') };
    font-size: 1.15rem;
    border-width: 1px;
    padding: ${ whitespace('less') };

    svg {
      margin: 0 ${ whitespace('less') } 0 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    margin: ${ whitespace() } 0 ;
    border-radius: ${ whitespace('least') };
    padding: ${ whitespace() };
    font-size: 2.5rem;
    border-width: 1px;

    svg {
      margin: 0 ${ whitespace() } 0 ${ whitespace('less') };
    }
  }
`;
