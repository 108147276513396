import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Link, Route
} from "react-router-dom";
import { fetchMerch, toggleFilters, updateFilters } from 'state/workflows/read-merch/action-creators';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import Button from 'design/atoms/button';
import Toggle from 'design/atoms/toggle';
import { fetchTags } from 'state/workflows/read-tags/action-creators';
import Geoloc from 'design/atoms/geoloc';
import Input from 'design/atoms/input';
import { PhotoPicker, S3Image } from 'aws-amplify-react';
import StyledHeader from 'design/atoms/styled-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTags, faDollarSign, faMusic, faMapMarkerAlt, faPencilAlt, faPlusCircle, faCamera, faTshirt, faChevronRight, faHandPointLeft, faHandPointDown } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'design/atoms/tooltip';

const ArtistCred = styled.div`
  position: absolute;
  font-size: .65rem!important;
  font-style: oblique;
  padding: ${ whitespace('least') };
  color: ${ color('grey', 'darkest') };
  right: ${ whitespace('less') };
  bottom: ${ whitespace('less') };
  border: 1px solid ${ color('grey', 'darkest')};
  background-color: ${ color('grey', 'lightest') };

  strong {
    font-size: .65rem!important;
    font-style: normal;
    text-shadow: none!important;
    color: ${ color('grey', 'darkest') } !important;
    margin-right: ${ whitespace('least') };
  }
`;

const FiltersWrapper = styled.div`
  position: relative;
  background: #101e26;

  .close {
    position: absolute;
    bottom: 0;
    right: 0;
    color: ${ color('blue') };
    line-height: 57px;
    display: inline-block;
    height: 57px;
    margin-right: ${ whitespace('less') };
  }
`;

const FilterWrapper = styled.div`
  background: #101e26;
  padding: ${ whitespace('least') };
  box-sizing: border-box;
  border-top: 1px solid ${ color('grey', 'darker') };

  p {
    color: ${ color('grey') };
    font-size: 12px;
    margin-top: -.35rem;
  }

  h3 {
    color: ${ color('grey', 'lighter') };
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    font-size: 12px;
  }

  label, .input {
    margin: 0!important;
    padding: 0!important;
  }

  @media only screen and (min-width: 0) {
    width: calc(100vw);
    position: relative;
    left: -5px;
    text-align: center;

    h3 {
      margin: 0;
      padding: 0 0 ${ whitespace('least') };
    }

    p {
      padding: ${ whitespace('least') } 0 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    border: 1px solid ${ color('grey', 'darker') };
    max-width: 50vw;
    text-align: right;

    p {
      padding: ${ whitespace('least') } 0 0;
    }
  }
`;

const BrowsePage = styled.article`
  background: ${ color('grey', 'darkest') };
`;

const FilterTriggers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #101e26;
  z-index: 999;

  .trigger {
    color: ${ color('blue') };
    cursor: pointer;

    &:hover {
      color: #fff;
      cursor: pointer;

    }
  }

  @media only screen and (min-width: 0) {
    font-size: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${ color('grey', 'darker') };

    .trigger {
      padding: ${ whitespace('less') } ${ whitespace('less') } ${ whitespace('less') };
    }
  }

  @media only screen and (min-width: 1200px) {
    position: static;
    font-size: 2rem;
    padding: 0 ${ whitespace('more') } ${ whitespace() };
    border-top: none;

    .trigger {
      padding: 0 ${ whitespace() };
    }
  }
`;

const GenreList = styled.div`
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

`;

const CategoryList = styled.div`
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const StyledParagraph = styled.p`
  color: ${ color('grey', 'light') };
  text-align: center;
  text-shadow: 1px 1px 1px #000;

  .brand-logo {
    font-size: 3rem;
  }

  strong {
    color: ${ color('grey', 'lightest') };
  }

  .and {
    margin: 0 -1rem;
    font-size: 1rem;
    font-weight: normal;
    color: ${ color('grey', 'light') };
  }

  .comma {
    margin: 0 -.5rem;
    font-size: 3rem;
    font-weight: normal;
    color: ${ color('grey', 'light') };
  }


  @media only screen and (min-width: 0) {
    font-size: .85rem;
    line-height: 1.15rem;
    width: auto;
    padding: ${ whitespace('least') };
    margin: 0;
    letter-spacing: 0;
    border-bottom: 1px solid ${ color('grey', 'darker') };

    strong {
      text-shadow: 1px 1px 1px #000;
      margin: 0 ${ whitespace('least') };

      &:first-of-type {
        font-size: 1em;
      }

      &:last-of-type {
        font-size: 1em;
      }

      span {
        margin: 0;
      }
    }

    span strong {
      margin-right: 0;
    }

    .and {
      margin: 0 0px 0 4px;
      font-size: .85rem;
    }

    .comma {
      margin: 0 3px 0 2px;
      font-size: .85rem;
    }

    .and + .comma {
      margin-left: -8px;
    }

    .cost {
      margin-left: 4px;
    }

    .from {
      margin-left: 3px;
    }

    .artists {
      margin: 0 3px;
    }
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1.35rem;
    line-height: 4rem;
    width: 70rem;
    padding: ${ whitespace() } 0 ${ whitespace('less') };
    margin: 0 auto;
    letter-spacing: 9px;
    border-bottom: none;

    strong {
      text-shadow: 3px 3px 1px #000;

      &:first-of-type {
        font-size: 3.5em;
      }

      &:last-of-type {
        font-size: 2em;
      }

      span {
        margin: 0 ${ whitespace('least') };
      }
    }

    .and {
      margin: 0 -1rem;
      font-size: 1rem;
    }

    .comma {
      margin: 0 -.5rem;
      font-size: 3rem;
    }
  }

`;

const MerchList = styled.ul`
  padding: 0;
  overflow: hidden;
  list-style: none;
  margin: 0;

  @media only screen and (min-width: 0) {
    margin-bottom: 57px;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 0;
  }

`;

const MerchPiece = styled.li`
  float: left;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  transition: border-color .5s;
  border: 1px solid #000;
  background: #fff;

  img {
    width: 100%;
    transition: opacity .5s;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: ${ color('grey', 'darkest') };

    .name {
      display: none;
    }
  }

  @media only screen and (min-width: 0) {
    width: calc(100vw / 1);
    height: calc(100vw / 1);
    border-bottom: 4px solid #000;
  }

  @media only screen and (min-width: 1200px) {
    width: calc(100vw / 6);
    height: calc(100vw / 6);
    border-bottom: 1px solid #000;

    &:hover {
      img {
        opacity: .85;
        transition: opacity .5s;
      }
    }

  }

`;

function generateResizeUrl(key, width, height) {
  const domain = 'https://d2m5fus4qpj770.cloudfront.net/';
  const bucket = 'merchgame204211-production';

  return `${domain}${btoa(JSON.stringify({
    bucket,
    key: `public/${ key }`,
    edits: {
      resize: {
        width,
        height,
        fit: 'cover'
      }
    }
  }))}`;
};

class ListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchMerch();
  }

  handleCategoryCheckbox(e) {
    const value = e.currentTarget.getAttribute('value');
    const categoryFilters = [ ...(this.props.categoryFilters || []) ];

    if (categoryFilters.includes(value)) {
      categoryFilters.splice(categoryFilters.indexOf(value), 1);
    } else {
      categoryFilters.push(value);
    }

    this.props.updateFilters({ categoryFilters })
  }

  handleGenreCheckbox(e) {
    const value = e.currentTarget.getAttribute('value');
    const genreFilters = [ ...this.props.genreFilters || [] ];

    if (genreFilters.includes(value)) {
      genreFilters.splice(genreFilters.indexOf(value), 1);
    } else {
      genreFilters.push(value);
    }

    this.props.updateFilters({ genreFilters });
  }

  render() {
    return (
      <BrowsePage>
        { console.log(this.state) }
        <FiltersWrapper>
          <StyledParagraph>
            <strong>
              {(
                this.props &&
                this.props.categoryFilters &&
                this.props.categoryFilters.length &&
                this.props.categoryFilters || ['Merchandise']
              ).map((category, i) => (
                <span key={ i }>
                  {(this.props.categoryFilters.length !== 1) && (i === this.props.categoryFilters.length - 1) ? (
                    <span key={ i }><span className="and">and</span> { category }</span>
                  ) : (
                    <span>
                      {(this.props.categoryFilters.length > 2) && (i < this.props.categoryFilters.length - 1) ? (
                        <span key={ i }>{ category }<span className="comma">,</span></span>
                      ) : (
                        <span key={ i }>{ category }</span>
                      )}
                    </span>
                  )}
                </span>
              ))}
              {
                this.props &&
                this.props.priceFilter &&
                <span className="cost">under ${ this.props.priceFilter }</span>
              }
            </strong>
            by
            { this.props &&
              this.props.genreFilters &&
              this.props.genreFilters.length ? (
              <span>
                <strong className="artists">
                  { this.props.genreFilters.map((genre, i) => (
                    <span key={ i }>
                      {(this.props.genreFilters.length !== 1) && (i === this.props.genreFilters.length - 1) ? (
                        <span key={ i }><span className="and">and</span> { genre }</span>
                      ) : (
                        <span>
                          {(this.props.genreFilters.length > 2) && (i < this.props.genreFilters.length - 1) ? (
                            <span key={ i }>{ genre }<span className="comma">,</span></span>
                          ) : (
                            <span key={ i }>{ genre }</span>
                          )}
                        </span>
                      )}
                    </span>
                  ))}
                </strong>
                artists from
              </span>
            ) : (
              <span>
                <strong>artists</strong> from
              </span>
            )}
            <strong className="from">
              { this.props && this.props.locationFilterLabel ? (
                <span>
                  { this.props.locationFilterLabel }
                </span>
              ) : (
                <span>
                  all across the country.
                </span>
              )}
            </strong>
          </StyledParagraph>

          <FilterTriggers>
            { this.state.filterOpen &&
              <FontAwesomeIcon
                onClick={() => this.setState({ filterOpen : false }) }
                className="close"
                title="Filter by Merch Category"
                icon={ faTimesCircle }
              />
            }

            <Tooltip
              placement="bottom-end"
              trigger="click"
              tooltip={
                <FilterWrapper>
                  <h3>Filter by Content Types</h3>
                  <p>Select all the Content Types you would like to see.</p>
                  <CategoryList>
                    { this.props.tags && this.props.tags.length > 0 && (
                      <div>
                        { this.props.tags.filter(t => t.category === 'Merch Category').map((tag, i) =>
                          <Toggle
                            key={ tag.id }
                            label={ tag.label }
                            type="button"
                            className={ this.props && this.props.categoryFilters && this.props.categoryFilters.includes(tag.label) ? 'checked' : 'not-checked' }
                            onClick={(e) => this.handleCategoryCheckbox(e)}
                          />
                        )}
                      </div>
                    )}
                  </CategoryList>
                </FilterWrapper>
              }
            >
              <FontAwesomeIcon
                title="Filter by Merch Category"
                icon={ faTags }
                size="2x"
              />
            </Tooltip>

            <Tooltip
              placement="bottom-end"
              trigger="click"
              tooltip={
                <FilterWrapper>
                  <h3>Filter by Max Price</h3>
                  <p>Enter the maximum price you're interested in.</p>

                  <Input
                    placeholder="Don't be cheap!"
                    value={ (this.props && this.props.priceFilter) || '' }
                    onChange={(e) => this.props.updateFilters({ priceFilter: e.target.value })}
                  />
                </FilterWrapper>
              }
            >
              <FontAwesomeIcon
                title="Filter by Max Price"
                icon={ faDollarSign }
                size="2x"
              />
            </Tooltip>

            <Tooltip
              placement="bottom-end"
              trigger="click"
              tooltip={
                <FilterWrapper>
                  <h3>Filter by Genres</h3>
                  <p>Select all the Genres you are interested in.</p>

                  <GenreList>
                    { this.props.tags && this.props.tags.length > 0 && (
                      <div>
                        { this.props.tags && this.props.tags.filter(t => t.category === 'Genre').map((tag, i) =>
                          <Toggle
                            key={ tag.id }
                            label={ tag.label }
                            name="genre"
                            type="button"
                            className={ this.props && this.props.genreFilters && this.props.genreFilters.includes(tag.label) ? 'checked' : 'not-checked' }
                            onClick={(e) => this.handleGenreCheckbox(e)}
                          />
                        )}
                      </div>
                    )}
                  </GenreList>
                </FilterWrapper>
              }
            >
              <FontAwesomeIcon
                title="Filter by Genres"
                icon={ faMusic }
                size="2x"
              />
            </Tooltip>

            <Tooltip
              placement="bottom-end"
              trigger="click"
              tooltip={
                <FilterWrapper>
                  <h3>Filter by Location</h3>
                  <p>Lookup the area you're interested in.</p>

                  <Geoloc
                    className="styled-geo"
                    context="browse"
                    initialValue={ this.props.locationFilterLabel }
                    placeholder='Try local?'
                    reference={(element) => this.geoSuggest = element }
                    onSuggestSelect={(suggest) => {
                      if (suggest) {
                        this.props.updateFilters({
                          locationFilterLabel: suggest.label,
                          locationFilter: [
                            suggest.location.lng,
                            suggest.location.lat
                          ]
                        });
                      }
                    }}
                  />
                </FilterWrapper>
              }
            >
              <FontAwesomeIcon
                title="Filter by Location"
                icon={ faMapMarkerAlt }
                size="2x"
              />
            </Tooltip>
          </FilterTriggers>
        </FiltersWrapper>

        <MerchList>
          { this.props.merch &&
            this.props.merch
            .filter((merch) => {
              return merch.status === 'SAVED';
            })
            .filter((merch) => {
              return !this.props.priceFilter || (merch.price <= this.props.priceFilter);
            })
            .filter((merch) => {
              return !!merch.categories.filter((m) => {
                return (this.props.categoryFilters.length === 0) || this.props.categoryFilters.includes(m);
              }).length;
            })
            .map((merch) => (
              <MerchPiece key={ merch.id } title={ merch.description }>
                <Link to={ `/browse/${merch.id}` }>
                  <img src={ generateResizeUrl(merch.imageKey, 1000, 1000) } />
                </Link>
              </MerchPiece>
            )
          )}
        </MerchList>
      </BrowsePage>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    merch: state.readMerch.merch,
    tags: state.readTags.tags,
    editFiltersMode: state.readMerch.editFiltersMode,
    priceFilter: state.readMerch.filters.priceFilter,
    categoryFilters: state.readMerch.filters.categoryFilters,
    genreFilters: state.readMerch.filters.genreFilters,
    locationFilterLabel: state.readMerch.filters.locationFilterLabel,
    locationFilter: state.readMerch.filters.locationFilter
  }
};

const mapDispatchToProps = {
  fetchMerch,
  fetchTags,
  toggleFilters,
  updateFilters
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPage);
