import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import StyledParagraph from 'design/atoms/styled-paragraph';
import CallToAction from 'design/atoms/call-to-action';
import Break from 'design/atoms/break';
import { fetchMerch, updateFilters } from 'state/workflows/read-merch/action-creators';
import whitespace from 'design/subatomics/whitespace';
import color from 'design/subatomics/colors';
import StyledHeader from 'design/atoms/styled-header';
import Geoloc from 'design/atoms/geoloc';
import Logo from 'design/atoms/logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'

const MarketingPage = styled.article`
  margin: 0;
`;

const ArtistCred = styled.div`
  position: absolute;
  font-size: .65rem!important;
  font-style: oblique;
  padding: ${ whitespace('least') };
  color: ${ color('grey', 'darkest') };
  right: ${ whitespace('less') };
  bottom: ${ whitespace('less') };
  border: 1px solid ${ color('grey', 'darkest')};
  background-color: ${ color('grey', 'lightest') };

  strong {
    font-size: .65rem!important;
    font-style: normal;
    text-shadow: none!important;
    color: ${ color('grey', 'darkest') } !important;
    margin-right: ${ whitespace('least') };
  }
`;

const MerchBlob = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${ color('grey', 'darkest') };
  background-position: center center;
  border-top: 1px solid ${ color('grey', 'dark')};
  border-bottom: 8px solid ${ color('grey', 'darkest')};

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: .85rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-shadow: 1px 1px 1px #000;
      color: ${ color('grey', 'lightest') };

      &:focus {
        outline-color: ${ color('blue') };
      }

      &:hover {
        cursor: pointer;
        color: #fff;
        strong {
          color: #fff;
        }
        svg {
          color: #00bfff;
        }
      }
    }

    .styled-geo {
      flex-direction: column;
      color: ${ color('grey', 'darkest')};
      align-items: flex-start;
      justify-content: flex-end;

      .geosuggest__suggests-wrapper {
        justify-content: flex-start;

        ul {
          li {
            span {
              text-shadow: none;
              color: ${ color('grey', 'darkest')};
            }

            &:hover {
              cursor: pointer;
              background: ${ color('grey', 'lighter')};
            }

          }
        }
      }
    }

    strong {
      font-size: 1rem;
      font-weight: strong;
      text-shadow: 1px 1px 1px #000;
      margin-left: ${ whitespace('least')};
    }

    label {
      flex-grow: 0;
    }

    svg {
      font-size: 2.4rem;
      color: ${ color('blue') };
      margin-right: ${ whitespace('least')};
    }
  }


  p {
    padding: 0;
  }

  p, strong, .brand-logo {
    color: ${ color('grey', 'lightest') };
    text-shadow: 2px 2px 1px #000;
  }

  strong {
    color: #fff;
    text-shadow: 4px 4px 1px #000;
  }

  .brand-logo {
    color: ${ color('blue') };
  }

  @media only screen and (min-width: 0) {
    height: 500px;
    background-image: url(${generateResizeUrl('images/merch-blob.jpg', 850, 850)});

    p {
      margin: ${ whitespace('less')};
      width: auto;
    }

    p, strong, .brand-logo {
      text-shadow: 1px 1px 1px #000;
    }

    strong {
      text-shadow: 1px 1px 1px #000;
    }

    label {
      width: 90vw;
    }

    .styled-geo {
      .geosuggest__suggests-wrapper {
        top: 50px;

        ul {
          top: 47px;

          li {
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }

    div {
      flex-wrap: wrap;

      svg {
        font-size: 1.5rem;
      }
      a {
        padding: ${ whitespace('least')};
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    background-image: url(${generateResizeUrl('images/merch-blob.jpg', 2000, 2000)});
    padding: ${ whitespace() } 0;

    p {
      margin: ${ whitespace('most')} !important ${ whitespace('most')} !important 0;
      width: 66.6%;
    }

    p, strong, .brand-logo {
      text-shadow: 2px 2px 1px #000;
    }

    strong {
      color: #fff;
      text-shadow: 4px 4px 1px #000;
    }

    label {
      width: 45rem;
    }

    .styled-geo {
      .geosuggest__suggests-wrapper {
        top: 100px;

        ul {
          top: 97px;

          li {
            span {
              font-size: 1.35rem;
            }
          }
        }
      }
    }

    div {
      svg {
        font-size: 2.4rem;
      }
      a {
        padding: ${ whitespace()};
      }
    }
  }

`;

const ArtistsBlob = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ color('grey', 'darkest') };
  background-position: center center;
  border-bottom: 8px solid ${ color('grey', 'darkest')};
  flex-direction: column;

  p {
    padding: 0;
  }

  p, strong, .brand-logo {
    color: ${ color('grey', 'lightest') };
  }

  strong, .brand-logo {
    color: #fff;
  }

  .brand-logo {
    color: ${ color('blue') }!important;
  }

  @media only screen and (min-width: 0) {
    height: 500px;
    background-image: url(${generateResizeUrl('images/artist-blob.jpg', 850, 850)});

    p {
      margin: ${ whitespace('less')};
      width: auto;
    }

    p, strong, .brand-logo {
      color: ${ color('grey', 'lightest') };
      text-shadow: 1px 1px 1px #000;
    }

    strong {
      text-shadow: 1px 1px 1px #000;
    }
  }

  @media only screen and (min-width: 1200px) {
    height: 750px;
    background-image: url(${generateResizeUrl('images/artist-blob.jpg', 2000, 2000)});

    p {
      margin: ${ whitespace('most')} !important ${ whitespace('most')} !important 0;
      width: 66.6%;
    }

    p, strong, .brand-logo {
      color: ${ color('grey', 'lightest') };
      text-shadow: 2px 2px 1px #000;
    }

    strong {
      text-shadow: 4px 4px 1px #000;
    }
  }

`;

const MerchList = styled.ul`
  padding: 0;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  list-style: none;
  display: flex;

  @media only screen and (min-width: 0) {
    white-space: nowrap;
  }

  @media only screen and (min-width: 1200px) {
    white-space: wrap;
  }
`;

const MerchPiece = styled.li`
  flex-shrink: 0;
  float: left;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  transition: border-color .5s;
  border: 1px solid #000;

  img {
    width: 100%;
    transition: opacity .5s;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: ${ color('grey', 'darkest') }

    .name {
      display: none;
    }
  }

  @media only screen and (min-width: 0) {
    width: calc(100vw / 3);
    height: calc(100vw / 3);
  }

  @media only screen and (min-width: 1200px) {
    width: calc(100vw / 6);
    height: calc(100vw / 6);

    &:hover {
      img {
        opacity: .85;
        transition: opacity .5s;
      }
    }
  }
`;

function generateResizeUrl(key, width, height) {
  const domain = 'https://d2m5fus4qpj770.cloudfront.net/';
  const bucket = 'merchgame204211-production';

  return `${domain}${btoa(JSON.stringify({
    bucket,
    key: `public/${ key }`,
    edits: {
      resize: {
        width,
        height,
        fit: 'cover'
      }
    }
  }))}`;
};

class HomePage extends React.Component {
  componentDidMount() {
    this.props.fetchMerch();
  }

  componentWillUnmount() {
    document.querySelector('.application-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  render() {
    return (
      <div>
        <MarketingPage>
          { this.props.merch.length > 0 &&
            <MerchList>
              { this.props.merch.slice(0, 6).map((merch = {}, i = 0) =>
                <MerchPiece key={ i } target="_blank" title={ `Click to visit the ${ merch.artist.name } storefront.` } href={ merch.url }>
                  <Link to={ `/browse/${merch.id}` }>
                    <img src={ generateResizeUrl(merch.imageKey, 500, 500) } />
                  </Link>
                </MerchPiece>
              )}
            </MerchList>
          }

          <MerchBlob>
            <ArtistCred><strong>Disareyen</strong> from Tampa, FL</ArtistCred>

            <StyledParagraph>
              Let <Logo /> help you find new gear from our <strong>incredible collection of merch and music </strong> by artists from all across the country. <strong>Browse by genre, location, price and more.</strong>
            </StyledParagraph>

            <div>
              <Geoloc
                className="styled-geo"
                initialValue={ this.props.locationFilterLabel }
                placeholder="Let's start local, what city are you in?"
                reference={(element) => this.geoSuggest = element }
                onSuggestSelect={(suggest) => {
                  if (suggest) {
                    this.props.updateFilters({
                      locationFilterLabel: suggest.label,
                      locationFilter: [
                        suggest.location.lng,
                        suggest.location.lat
                      ]
                    });
                  }
                }}
              />
            { this.props.locationFilterLabel &&
              <Link to="/browse">
                <FontAwesomeIcon title="Free features and Pro Account pricing" icon={ faSearch } />
                Search for Merch in <strong>{ this.props.locationFilterLabel }</strong>
              </Link>
            }

            </div>
          </MerchBlob>

          <ArtistsBlob>
            <ArtistCred><strong>Sustenance</strong> from Atlanta, GA</ArtistCred>

            <StyledParagraph>
              For Artists, <Logo /> offers <strong> a FREE platform</strong> for sharing your merch &amp; music<strong> without having to worry about post throttling and ad restrictions</strong> like many of the larger social media networks.
            </StyledParagraph>

            <CallToAction to="/dashboard">
              <FontAwesomeIcon icon={ faLongArrowAltUp } />
              Level Up
            </CallToAction>
          </ArtistsBlob>
        </MarketingPage>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    merch: state.readMerch.merch,
    locationFilterLabel: state.readMerch.filters.locationFilterLabel,
    locationFilter: state.readMerch.filters.locationFilter
  }
};

const mapDispatchToProps = {
  fetchMerch,
  updateFilters
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
