import {
  ERRORED_FETCH_MERCH,
  LIST_MERCH,
  TOGGLE_FILTERS,
  UPDATE_FILTERS
} from 'state/workflows/read-merch/action-types';

const initialState = {
  merch: [],
  fetchMerchErrored: false,
  fetchMerchError: null,
  editFiltersMode: false,
  filters: {
    genreFilters: [ ],
    locationFilter: [],
    locationFilterLabel: null,
    categoryFilters: [ ],
    priceFilter: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_MERCH: {
      return {
        ...state,
        merch: action.payload,
        fetchMerchErrored: false,
        fetchMerchError: null
      };
    }

    case ERRORED_FETCH_MERCH: {
      return {
        ...state,
        artists: [],
        fetchMerchErrored: true,
        fetchMerchError: action.payload
      };
    }

    case TOGGLE_FILTERS: {
      return {
        ...state,
        editFiltersMode: !state.editFiltersMode
      }
    }

    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    }

    default:
      return state;
  }
};
