import { API, graphqlOperation } from 'aws-amplify';
import { put, takeEvery } from 'redux-saga/effects';

// import {
//   listArtists
// } from 'graphql/queries';

import {
  FETCH_ARTISTS,
  ERRORED_FETCH_ARTISTS,
  LIST_ARTISTS
} from 'state/workflows/read-artists/action-types';

const fetchArtistsRequest = async () => {
  // return await API.graphql(graphqlOperation(listArtists, { limit: 2000 }));
};

function* fetchArtistsAsync() {
  try {
    const { data: { listArtists: { items } } } = yield fetchArtistsRequest();
    yield put({ type: LIST_ARTISTS, payload: items });
  } catch (e) {
    // console.error(e);
    yield put({ type: ERRORED_FETCH_ARTISTS });
  }
};

const sagas = function* watchFetchArtistsAsync() {
  yield takeEvery(FETCH_ARTISTS, fetchArtistsAsync);
};

export default sagas;
