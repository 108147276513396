const fontSize = {
  smallest: '.6rem',
  smaller: '.85rem',
  small: '1rem',
  base: '1.2rem',
  large: '1.5rem',
  larger: '2.4rem',
  largest: '5.3rem',
};

export default (size = 'base') => {
  return fontSize[size];
}
