import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Link,
  Redirect
} from "react-router-dom";
import { fetchCurrentUser, createAffiliateId, addAffiliateReferrer, affiliateNoThanks } from 'state/workflows/fetch-current-user/action-creators';
import { fetchArtists } from 'state/workflows/read-artists/action-creators';
import StyledParagraph from 'design/atoms/styled-paragraph';
import { updateEditingMerchDetails } from 'state/workflows/edit-merch/action-creators';
import Input from 'design/atoms/input';
import Logo from 'design/atoms/logo';
import Button from 'design/atoms/button';
import { toggleAddMerch } from 'state/workflows/add-merch/action-creators';
import StyledHeader from 'design/atoms/styled-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import { faCheck, faBan, faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Toggle from 'design/atoms/toggle';
import TagList from 'design/atoms/tag-list';
import Form from 'design/templates/application-form';

const ArtistDetails = styled.div`
  padding-top: 16px;
  text-align: center;
  width: 75%;
  margin: ${ whitespace('more') } auto;
  color: ${ color('grey', 'darker') };

  p:first-of-type {
    font-size: 1.35rem;
  }

  p:last-of-type {
    color: ${ color('grey', 'dark') };
  }

  .social {
    margin: ${ whitespace() } 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0 ${ whitespace('less') };
      padding: 0;
      display: inline-block;

      a {
        font-size: 2rem;
        color: ${ color('blue') };
      }
    }
  }

  input:hover {
    border-color: ${ color('grey', 'darker') }!important;
    cursor: default;
  }
`;

const LogoBadge = styled.div`
  display: block;
  position: absolute;
  top: ${ whitespace() };
  left: ${ whitespace() };
  border: 2px solid #fff;
  background: #fff;
  box-shadow: 1px 1px 2px ${ color('grey', 'dark') };
  z-index: 999;
  border-radius: ${whitespace('least') };

  a {
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 14px;
    color: ${ color('blue') };
    position: relative;
    top: 28px;

    svg {
      margin-right: ${ whitespace('least') };
    }
  }

  div {
    position: relative;
    height: 100%;
  }
`;

const PageBanner = styled.div`
  margin-top: 50px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-items: center;
  flex: 1 1 auto;

  h2 {
    position: relative;
    top: -50px;
    flex: 1 1 auto;
    font-size: 3rem;
    color: ${ color('grey', 'darker') };
    font-weight: 100;

    a {
      display: block;
      margin-top: ${ whitespace('less') };
      margin-left: ${ whitespace('less') };
      color: ${ color('blue') };
      text-decoration: none;

      svg {
        position: relative;
        top: -1px;
        font-size: 2.5rem;
        margin-right: ${ whitespace('least') };
      }
    }
  }
`;

const MerchList = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin-bottom: ${whitespace('more') };
  border-top: 1px solid ${ color('grey', 'darkest') };

  h3 {
    flex-basis: 100%;
    font-weight: 100;
    letter-spacing: 2px;
    text-align: right;
    color: ${ color('grey', 'darker') };
    font-size: 1.75rem;
    margin: 0;
    padding: ${ whitespace('least') } ${ whitespace('less') } ${ whitespace() };

    svg {
      margin: 0 0 0 ${ whitespace('less') };
      color: ${ color('blue') };
    }
  }

  p {
    flex-basis: 100%;
    margin: ${whitespace() } 0;
    padding: 0;
  }
`;

const MerchItem = styled.div`
  box-sizing: border-box;
  border: 1px solid ${ color('grey', 'lighter') };
  list-style: none;
  margin: ${whitespace('less') };
  padding: ${whitespace('least') };
  align-items: center;
  justify-content: center;
  display: flex;
  width: calc((100vw / 4) - (${whitespace('less') } * 2));
  background: #fff;
  box-shadow: 1px 1px 2px ${ color('grey', 'dark') };
  position: relative;
  border-radius: ${whitespace('least') };

  &.inactive {
    span {
      color: ${ color('grey', 'lighter') };
    }
    img {
      background: ${ color('grey', 'lighter') };
      opacity: .15;
    }

    &:hover {
      background: ${ color('grey', 'lightest') };

      .deactivate, span {
        color: ${ color('grey', 'dark') };
        background: ${ color('grey', 'lightest') };
        border-color: ${ color('grey', 'lightest') };
      }
    }

  }

  .deactivate {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    background: #fff;
    color: red;
    padding: ${whitespace('less') };
    border-radius: 0 ${whitespace('least') } 0 0;
    border: ${whitespace('least') } solid ${ color('blue') };
    z-index: 999;

    &:hover {
      color: ${ color('blue') };
      cursor: pointer;
    }
  }

  span {
    font-size: 3rem;
    background: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: ${whitespace('less') };
    line-height: 3rem;
    color: ${ color('grey', 'darker') };

    border-radius: ${whitespace('least') } 0 0 0;

    sup {
      font-size: 1.65rem;
    }
  }

  &:hover {
    background: ${ color('blue') };

    .deactivate {
      display: block;
    }

    span {
      background: ${ color('blue') };
    }
  }

  img {
    width: 100%;
    border-radius: ${whitespace('least') };
    background: #fff;
    margin-bottom: ${ whitespace('least', 'negative')}
  }
`;

const Dashboard = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;

  &.partner-questionaire {
    padding: 0;

    p {
      padding: 0 ${whitespace('less') };
      box-sizing: border-box;
    }

    label {
      padding: 0 ${whitespace('less') };
      box-sizing: border-box;
    }

    label {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    footer {
      width: 100%;
      box-sizing: border-box;
    }

    @media only screen and (min-width: 0) {
      footer {
        margin-top: ${ whitespace('more') };
        padding: 0;
        position: fixed;
        bottom: 0;
      }
    }

    @media only screen and (min-width: 1200px) {
      footer {
        margin-top: ${ whitespace('more') };
        padding: 0;
        position: static;
      }
    }

  }
`;

function generateResizeUrl(key, width, height) {
  const domain = 'https://d2m5fus4qpj770.cloudfront.net/';
  const bucket = 'merchgame204211-production';

  return `${domain}${btoa(JSON.stringify({
    bucket,
    key: `public/${ key }`,
    edits: {
      resize: {
        width,
        height,
        fit: 'cover'
      }
    }
  }))}`;
}

class DashboardPage extends React.Component {
  componentWillUnmount() {
    document.querySelector('.application-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    document.querySelector('body').focus();
  }

  render() {
    if (!this.props.currentUser?.sub) {
      return ( <Redirect to="/sign-in" /> );
    } else if (
      !this.props.affiliateId ||
      !this.props.affiliateReferrer
    ) {
      return (
        <Dashboard className="partner-questionaire">
        <Form>
          <StyledParagraph>
            If you like what we're doing and <strong>want to help build the community</strong>, you can <strong>create a PartnerId now.</strong> If you're not sure yet, you can always do it later.
          </StyledParagraph>

          <Input
            autoFocus={ true }
            label='Get My Partner Id'
            placeholder='Get the credit you deserve. Create a PartnerId.'
            value={ this.props.affiliateId || '' }
            onBlur={(e) => {
              this.props.createAffiliateId(e.target.value);
            }}
          />

          <StyledParagraph>
            If you were <strong>referred by a friend, they may have given you their PartnerId.</strong> <strong>If so, enter it below,</strong> that's how we make sure they get credit.
          </StyledParagraph>

          <Input
            label='Who Sent You?'
            placeholder='Enter their PartnerId here.'
            value={ this.props.affiliateReferrer || '' }
            onBlur={(e) => {
              this.props.addAffiliateReferrer(e.target.value);
            }}
          />

          <StyledParagraph>
            To find out more about how we reward our Partners, visit our <Link to="/partners">Partners page</Link>.
          </StyledParagraph>

          <footer>
            { !this.props.affiliateReferrer &&
              this.props.affiliateId &&
              <Button
                type="button"
                label="I'm Done"
                action={(e) => this.props.affiliateNoThanks({
                  referrer: 'NO_THANKS',
                  shareableId: this.props.affiliateId
                })
              } />
            }

            { !this.props.affiliateId &&
              this.props.affiliateReferrer &&
              <Button
                type="button"
                label="I'm Done"
                action={(e) => this.props.affiliateNoThanks({
                  shareableId: 'NO_THANKS',
                  referrer: this.props.affiliateReferrer
                })
              } />
            }

            { (!this.props.affiliateId || this.props.affiliateId === 'NO_THANKS') &&
              (!this.props.affiliateReferrer || this.props.affiliateReferrer === 'NO_THANKS') &&
              <Button
                type="button"
                label="I Don't Care About This."
                action={(e) => this.props.affiliateNoThanks() }
              />
            }
          </footer>
        </Form>
        </Dashboard>
      );
    } else if (!this.props.currentUserArtist.id) {
      return ( <Redirect to="/new-artist" /> );
    } else {
      return (
        <Dashboard>
          <StyledHeader>
            { this.props.currentUserArtist.name } Dashboard
          </StyledHeader>

          { this.props.currentUserArtist.logoKey &&
            <div>
              <LogoBadge>
                <img src={ generateResizeUrl(this.props.currentUserArtist.logoKey, 200, 200) } />
                <Link to="/dashboard/update-artist">
                  <FontAwesomeIcon
                    title="Edit Artist Details"
                    icon={ faPencilAlt }

                  />
                  Click to edit artist details
                </Link>
              </LogoBadge>
            </div>
          }


          { !this.props.merch?.length ? (
            <PageBanner>
              <h2>
                You haven't added any merch yet.
                <Link to="/dashboard/add-merch">
                  <FontAwesomeIcon
                    title="Add Merch"
                    icon={ faPlusCircle }
                  />

                  Do it now?
                </Link>
              </h2>
            </PageBanner>
          ) : (
            <div>
              <ArtistDetails className="artist-details">
                <p>{ this.props.currentUserArtist.description }</p>
                <p>{ this.props.currentUserArtist.locationLabel }</p>

                <ul className="social">
                  { this.props.currentUserArtist.links[0] &&
                    this.props.currentUserArtist.links[0].url &&
                    <li>
                      <a href={ this.props.currentUserArtist.links[0].url }>
                        <FontAwesomeIcon
                          title="Facebook"
                          icon={ faFacebook }
                        />
                      </a>
                    </li>
                  }
                  { this.props.currentUserArtist.links[1] &&
                    this.props.currentUserArtist.links[1].url &&
                    <li>
                      <a href={ this.props.currentUserArtist.links[1].url }>
                        <FontAwesomeIcon
                          title="Twitter"
                          icon={ faTwitter }
                        />
                      </a>
                    </li>
                  }
                  { this.props.currentUserArtist.links[2] &&
                    this.props.currentUserArtist.links[2].url &&
                    <li>
                      <a href={ this.props.currentUserArtist.links[2].url }>
                        <FontAwesomeIcon
                          title="Instagram"
                          icon={ faInstagram }
                        />
                      </a>
                    </li>
                  }
                </ul>

                <TagList>
                  { this.props.currentUserArtist.genres.map((tag, i) =>
                    <Toggle
                      key={ i }
                      label={ tag }
                      name="genre"
                      type="button"
                      className='checked'
                    />
                  )}
                </TagList>
              </ArtistDetails>

              <MerchList>
                <h3>
                  Merch &amp; Music
                  <Link to="/dashboard/add-merch">
                    <FontAwesomeIcon
                      title="Instagram"
                      icon={ faPlusCircle }
                    />
                  </Link>
                </h3>

                { this.props.currentUserArtist.merch.map((merchPiece, i) => (
                  <MerchItem className={ merchPiece.status === 'INACTIVE' ? 'inactive' : 'active'} key={ i }>
                    { merchPiece.status === 'SAVED' &&
                      <button className="deactivate" onClick={() => this.props.updateEditingMerchDetails(merchPiece.id, 'status', 'INACTIVE') }>
                        <FontAwesomeIcon
                          title="Edit Artist Details"
                          icon={ faCheck }
                        />
                      </button>

                    }

                    { merchPiece.status === 'INACTIVE' &&
                      <button className="deactivate" onClick={() => this.props.updateEditingMerchDetails(merchPiece.id, 'status', 'SAVED') }>
                        <FontAwesomeIcon
                          title="Edit Artist Details"
                          icon={ faBan }
                        />
                      </button>
                    }

                    <Link to={ `/dashboard/edit-merch/${merchPiece.id}` }>
                      <img src={ generateResizeUrl(merchPiece.imageKey, 1000, 1000) } />
                      <span className="price">
                        <sup>$</sup>{ merchPiece.price || 0 }
                      </span>
                    </Link>
                  </MerchItem>
                ))}
              </MerchList>
            </div>
          )}
        </Dashboard>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    affiliateId: state.fetchCurrentUser.affiliateId,
    affiliateReferrer: state.fetchCurrentUser.affiliateReferrer,
    merch: state.fetchUserArtist.currentUserArtist.merch.filter((m) => (m.status === 'SAVED' || m.status === 'INACTIVE')),
    currentUserArtist: state.fetchUserArtist.currentUserArtist,
    id: state.fetchUserArtist.currentUserArtist.id,
    artists: state.readArtists.artists,
    currentUser: state.fetchCurrentUser.currentUser,
    artistsFetched: state.readArtists.artistsFetched,
    registrationStatus: state.fetchUserArtist.currentUserArtist.registrationStatus
  }
};

const mapDispatchToProps = {
  fetchCurrentUser,
  fetchArtists,
  toggleAddMerch,
  updateEditingMerchDetails,
  createAffiliateId,
  addAffiliateReferrer,
  affiliateNoThanks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
