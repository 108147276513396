/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateAffiliateDetails = /* GraphQL */ `
  mutation UpdateAffiliateDetails($input: UpdateAffiliateDetailsInput!) {
    UpdateAffiliateDetails(input: $input) {
      data {
        id
        owner
        referrer
        shareableId
      }
      error {
        message
      }
    }
  }
`;
export const createArtist = /* GraphQL */ `
  mutation CreateArtist($input: CreateArtistInput!) {
    createArtist(input: $input) {
      data {
        id
        name
        locationLabel
        locationCoordinates
        genres
        registrationStatus
        description
        logoKey
        contactPoints {
          label
          value
        }
        links {
          label
          url
        }
        merch {
          id
          name
          description
          price
          url
          categories
          imageKey
          status
        }
      }
      error {
        message
      }
    }
  }
`;
export const updateArtist = /* GraphQL */ `
  mutation UpdateArtist($input: UpdateArtistInput!) {
    updateArtist(input: $input) {
      data {
        id
        name
        locationLabel
        locationCoordinates
        genres
        registrationStatus
        description
        logoKey
        contactPoints {
          label
          value
        }
        links {
          label
          url
        }
        merch {
          id
          name
          description
          price
          url
          categories
          imageKey
          status
        }
      }
      error {
        message
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      category
      label
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      category
      label
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      category
      label
    }
  }
`;
