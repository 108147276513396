import { decoratedLinks, decoratedContactPoints } from 'state/workflows/fetch-user-artist/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { addMerch } from 'state/workflows/add-merch/action-creators';
import Input from 'design/atoms/input';
import Button from 'design/atoms/button';
import { fetchTags } from 'state/workflows/read-tags/action-creators';
import Textarea from 'design/atoms/textarea';
import StyledParagraph from 'design/atoms/styled-paragraph';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';
import styled from 'styled-components'
import Toggle from 'design/atoms/toggle';
import { PhotoPicker, S3Image } from 'aws-amplify-react';
import uuid from 'uuid/v4';
import { Storage } from 'aws-amplify'
import { toggleAddMerch, completeNewMerchCreation } from 'state/workflows/add-merch/action-creators';
import CreateTag from 'design/workflows/create-tag';
import StyledHeader from 'design/atoms/styled-header';
import { Redirect, Link } from "react-router-dom";
import Form from 'design/templates/application-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlusCircle, faCamera, faTshirt, faChevronRight, faHandPointLeft, faHandPointDown } from '@fortawesome/free-solid-svg-icons';
import { updateNewArtistDetails } from 'state/workflows/fetch-user-artist/action-creators';
import { updateMerchItemEditingId } from 'state/workflows/edit-merch/action-creators';
import { editingMerchItem } from 'state/workflows/edit-merch/selectors';
import { updateEditingMerchDetails } from 'state/workflows/edit-merch/action-creators';
import { updateNewMerchDetails } from 'state/workflows/add-merch/action-creators';
import { updateArtist } from 'state/workflows/update-artist/action-creators';
import { FileInput, FileInputWrapper, FileEditWrapper } from 'design/atoms/file-upload';
import TagList from 'design/atoms/tag-list';
import Tooltip from 'design/atoms/tooltip';
import { withRouter } from "react-router";
import Loader from 'design/atoms/loader';

const UploadLogo = styled(FontAwesomeIcon)`
  display: inline-block;
  line-height: auto;
  color: ${ color('blue') };
`;


class EditMerch extends React.Component {
  constructor(props) {
    super(props);
    this.props.updateMerchItemEditingId(this.props.match.params.merch);
  }

  handleCategorySelection({ currentTarget }) {
    const value = currentTarget.getAttribute('value');
    const categories = [ ...(this.props.editingMerchItem.categories || []) ];

    if (categories.includes(value)) {
      categories.splice(categories.indexOf(value), 1);
      this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'categories', categories);
    } else if (categories.length <= 4) {
      categories.push(value);
      this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'categories', categories);
    }
  }

  async handleFile({ target: { files } }) {
    const file = files?.length && files[0];
    const key = file && `images/${uuid()}-${file.name}`;

    if (!file) return;
    try {
      await Storage.put(key, file, { contentType: file.type });
      this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'imageKey', key);
    } catch (err) { console.error('error: ', err); }
  }

  render() {
    if (this.props.match.params.merch !== this.props.editingMerchItem?.id) {
      return (
        <Loader className="dashboard app-loader">
          <FontAwesomeIcon spin icon={ faTshirt } />
        </Loader>
      );
    } else if (!this.props.editingMerchItem) {
      return ( <Redirect to="/dashboard" /> );
    } else if (!this.props.currentUserArtist) {
      return ( <Redirect to="/sign-in" /> );
    } else {
      return (
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <StyledHeader>
            <Link to="/dashboard">{ this.props.currentUserArtist.name } Dashboard</Link>
            <FontAwesomeIcon icon={ faChevronRight } />
            Edit Merch
          </StyledHeader>

          <section>
            <StyledParagraph>
              <strong>What are you selling?</strong> Select one or more categories
              that <strong>best describes your merch. </strong> If we don't have
              it, <strong>add a new category.</strong>
            </StyledParagraph>

            <TagList>
              { this.props.categoryTags?.length &&
                this.props.categoryTags.map((tag, i) =>
                <Toggle
                  key={ tag.id }
                  label={ tag.label }
                  name="category"
                  type="button"
                  className={ this.props.editingMerchItem?.categories?.includes(tag.label) ? 'checked' : 'not-checked' }
                  onClick={(e) => this.handleCategorySelection(e)}
                />
              )}

              <Tooltip
                placement="bottom-end"
                trigger="click"
                tooltip={
                  <CreateTag
                    placeholder="What Merch Category are we missing?"
                    category="Merch Category"
                  />
                }
              >
                <FontAwesomeIcon
                  title="Add another Merch Category"
                  icon={ faPlusCircle }
                  size="2x"
                />
              </Tooltip>
            </TagList>

            <StyledParagraph>
              <strong>Now for a bit more detail.</strong> This is your chance
              to really sell it. <strong>Use the good words.</strong>
            </StyledParagraph>

            <Input
              required={ true }
              label='Product Name'
              placeholder='What do you call it?'
              value={ this.props.editingMerchItem?.name || '' }
              onBlur={(e) => {
                this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'name', e.target.value);
              }}
            />

            <Input
              required={ true }
              label='Price'
              placeholder='How much does it cost?'
              value={ this.props.editingMerchItem?.price }
              onBlur={(e) => {
                this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'price', e.target.value);
              }}
            />

            <Textarea
              label='Description'
              placeholder='Are there options; is it Limited Edition?'
              value={ this.props.editingMerchItem?.description }
              onBlur={(e) => { this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'description', e.target.value) }}
            />

            <Input
              required={ true }
              label='URL'
              placeholder='Where can I buy it online?'
              value={ this.props.editingMerchItem?.url }
              onBlur={(e) => { this.props.updateEditingMerchDetails(this.props.editingMerchItemId, 'url', e.target.value)}}
            />

            <FileEditWrapper>
              <S3Image imgKey={ this.props.editingMerchItem?.imageKey } />
              <FileInput
                type="file"
                onChange={(e) => this.handleFile(e)}
              />
              <FontAwesomeIcon
                title="Edit Merch Image"
                icon={ faPencilAlt }
              />
              Click to edit merch image
            </FileEditWrapper>
          </section>
        </Form>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    state,
    editingMerchItemId: state.editMerch.editingMerchItemId,
    editingMerchItem: editingMerchItem(state),
    tags: state.readTags.tags,
    currentUser: state.fetchCurrentUser.currentUser,
    currentUserArtist: state.fetchUserArtist.currentUserArtist,
    categoryTags: state.readTags.tags.filter(t => t.category === 'Merch Category').sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (b.label > a.label) {
        return -1;
      } else {
        return 0;
      }
    })
  }
};

const mapDispatchToProps = {
  addMerch,
  toggleAddMerch,
  updateNewArtistDetails,
  updateNewMerchDetails,
  updateArtist,
  completeNewMerchCreation,
  updateMerchItemEditingId,
  updateEditingMerchDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditMerch));
