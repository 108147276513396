import {
  ERRORED_FETCH_ARTISTS,
  LIST_ARTISTS
} from 'state/workflows/read-artists/action-types';

const initialState = {
  artists: [],
  fetchArtistsErrored: false,
  fetchArtistsError: null,
  artistsFetched: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_ARTISTS: {
      return {
        ...state,
        artists: action.payload,
        artistsFetched: true,
        fetchArtistsErrored: false,
        fetchArtistsError: null
      };
    }

    case ERRORED_FETCH_ARTISTS: {
      return {
        ...state,
        artists: [],
        fetchArtistsErrored: true,
        fetchArtistsError: action.payload
      };
    }

    default:
      return state;
  }
};
