import {
  TOGGLE_UPDATE_ARTIST
} from 'state/workflows/update-artist/action-types';

const initialState = {
  updateArtistMode: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_UPDATE_ARTIST: {
      return {
        ...state,
        updateArtistMode: !state.updateArtistMode
      };
    }

    default:
      return state;
  }
};
