// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f4fb0449-5800-4fd1-945a-c252d386d12d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Hsi6KH4V5",
    "aws_user_pools_web_client_id": "2t6lvp5mh1lk0ls3c2job3sjvj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://pwvn5bs5hjhfxecaxp6m5uyhxu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-64dq433mavhxdn3vwpwr2wct5u",
    "aws_user_files_s3_bucket": "merchgame204211-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "merchgame-20200402002953-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dks22um0w2mag.cloudfront.net"
};


export default awsmobile;
