/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fetchUserAffiliate = /* GraphQL */ `
  query FetchUserAffiliate($input: fetchUserAffiliate!) {
    fetchUserAffiliate(input: $input) {
      data {
        id
        owner
        referrer
        shareableId
      }
      error {
        message
      }
    }
  }
`;
export const fetchUserArtist = /* GraphQL */ `
  query FetchUserArtist($input: FetchUserArtistInput!) {
    fetchUserArtist(input: $input) {
      data {
        id
        name
        locationLabel
        locationCoordinates
        genres
        registrationStatus
        description
        logoKey
        contactPoints {
          label
          value
        }
        links {
          label
          url
        }
        merch {
          id
          name
          description
          price
          url
          categories
          imageKey
          status
        }
      }
      error {
        message
      }
    }
  }
`;
export const queryForMerch = /* GraphQL */ `
  query QueryForMerch($input: QueryForMerchInput) {
    queryForMerch(input: $input) {
      data {
        id
        name
        locationLabel
        genres
        description
        logoKey
        contactPoints {
          label
          value
        }
        links {
          label
          url
        }
        merch {
          id
          name
          description
          price
          url
          categories
          imageKey
          status
        }
      }
      error {
        message
      }
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      category
      label
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        label
      }
      nextToken
    }
  }
`;
