import React from 'react';
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import fontSize from 'design/subatomics/font-size';

// Styles

const StyledInput = styled.input`
  color: ${ color('grey', 'darkest') };
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .label {
    box-sizing: border-box;
    color: ${ color('grey', 'light') };
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: nowrap;
  }

  .input {
    display: flex;
    box-sizing: border-box;

    input {
      box-sizing: border-box;
      border: 1px solid ${ color('grey') };
      color: ${ color('grey', 'dark') };
      outline: none;

      &:disabled {
        border: none;
        color: ${ color('grey') };
      }

      &:focus {
        border: 1px solid ${ color('grey', 'darker') };
        color: ${ color('grey', 'darker') };
      }
    }
  }

  @media only screen and (min-width: 0) {
    margin: 0;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 ${ whitespace('least') };
    width: 100%;

    .label {
      flex: 1 1 100%;
      padding: 0;
      margin: 0;
      font-size: .85rem;
      text-align: left;
    }

    .input {
      flex: 1 1 100%;
      padding: ${ whitespace('least') } 0;

      input {
        width: 100%;
        flex: 1 1 100%;
        padding: ${ whitespace('less') };
        font-size: 1rem;
        border-radius: ${ whitespace('least') };
      }
    }

  }

  @media only screen and (min-width: 1200px) {
    margin: ${ whitespace() }!important;
    flex-wrap: nowrap;
    margin: ${ whitespace() };
    padding: ${ whitespace('most') };
    width: 100%;
    margin: ${ whitespace() };
    padding: ${ whitespace('most') };
    width: 100%;

    .label {
      flex: 1 1 33.4%!important;
      padding: ${ whitespace() } 0!important;
      margin: ${ whitespace('less') }!important;
      font-size: ${ fontSize('large') }!important;
      text-align: right!important;
    }

    .input {
      padding: ${ whitespace('least') } ${ whitespace('most') } ${ whitespace('least') } 0;
      margin: 0 ${ whitespace() } 0 0;

      input {
        width: 100%!important;
        flex: 1 1 100%!important;
        padding: ${ whitespace() }!important;
        font-size: 2.4rem!important;
        border-radius: ${ whitespace('least') }!important;
      }
    }
  }
`;

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  onChange(e) {
    this.setState({ value: e.target.value });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onBlur(e) {
    if (this.props.required && !this.input.value) {
      this.input.setAttribute('placeholder', 'This field is required.');
      this.input.focus();
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.props.clearOnEnter) {
        this.setState({ value: '' });
      } else {
        this.input.blur();
      }
    }
  }

  render() {
    return (
      <StyledLabel>
        { this.props.label && (
          <span className="label">
            { this.props.label }
            { this.props.required && <sup title="Required">*</sup> }
          </span>
        )}
        <span className="input">
          <StyledInput
            ref={ref => this.input = ref }
            className={ this.props.className }
            name={ this.props.name }
            value={ this.state.value }
            title={ this.props.title }
            placeholder={ this.props.placeholder }
            alt={ this.props.alt }
            type={ this.props.type || 'text' }
            disabled={ this.props.disabled || false }
            autoFocus={ this.props.autoFocus || false }
            required={ this.props.required || false }
            onChange={(e) => this.onChange(e) }
            onBlur={(e) => this.onBlur(e) }
            onKeyPress={(e) => {
              if (this.props.onKeyPress) {
                this.props.onKeyPress(e)
              }
              this.onKeyPress(e)
            }}
          />
        </span>
      </StyledLabel>
    );
  }
};
