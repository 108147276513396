import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import styled from 'styled-components';
import color from 'design/subatomics/colors';
import whitespace from 'design/subatomics/whitespace';
import { PhotoPicker, S3Image } from 'aws-amplify-react';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import StyledHeader from 'design/atoms/styled-header';
import { faPencilAlt, faPlusCircle, faLink, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Toggle from 'design/atoms/toggle';
import TagList from 'design/atoms/tag-list';

const Overlay = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
`;

const MerchDetails = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  z-index: 9999;
  background: ${ color('grey', 'darkest') };

  .artist-logo {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center center;
    opacity: .2;
    background-size: 100% 100%;
    z-index: -1;
  }

  @media only screen and (min-width: 0) {
    grid-template-columns: 100%;

    .artist-logo {
      display: none;
    }
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 50% 50%;

    .artist-logo {
      display: none;
    }
  }

`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  z-index: 999;
  position: relative;

  input.checked {
    background: ${color('grey', 'lighter')};
    color: ${color('grey', 'darker')};
    &:hover {
      border-color: ${ color('grey', 'darker')};
      cursor: default;
    }
  }

  .social {
    margin: 0;
    padding: 0;
    z-index: 999;
    text-align: center;

    li {
      list-style: none;
      margin: 0 ${ whitespace('less') } ${ whitespace() };
      padding: 0;
      display: inline-block;
      color: ${ color('grey', 'light') };

      svg {
        font-size: 2rem;
        color: #00bfff;
      }

      strong {
        font-weight: bold;
        text-transform: uppercase;
        font-size: .7rem;
      }

      a {
        color: ${ color('grey', 'light') };
        text-decoration: none;

        span {
          position: relative;
          top: -6px;
          margin-left: ${ whitespace('least') };
          font-size: .85rem;
        }

        &:hover {

          svg {
            cursor: pointer;
            color: ${ color('blue')};
          }

        }

      }

      &.generic-link {
        display: block;

        a {
          text-decoration: none;
        }

        svg {
          line-height: 1rem;
        }

        span {
          position: relative;
          top: -6px;
          margin-left: ${ whitespace('least') };
          font-size: .85rem;
        }
      }
    }
  }

  h1 {
    line-height: ${ whitespace('more') };
    flex-flow: column;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: ${ color('grey', 'lighter') };

    span {
      text-shadow: none;
      display: inline-flex;
      flex-flow: row;
      whitespace: nowrap;
      flex-wrap: nowrap;
      align-items: baseline;
      color: ${ color('grey', 'lighter') };

      span {
        margin: 0;
        color: ${ color('grey', 'light') };
      }
    }

    .price {
      color: ${ color('grey', 'lightest') };
      display: flex;
      align-items: flex-start;
      display: inline-block;
      font-weight: bold;

      .dollar-sign {
        position: relative;
      }

      .name {
        color: ${ color('grey', 'lighter') };
        display: inline-block;
        font-weight: light;
      }
    }
  }

  p {
    color: ${ color('grey', 'light') };
    text-align: left;
    line-height: ${ whitespace() };
    font-size: .85rem;
  }

  @media only screen and (min-width: 0) {
    padding-bottom: ${ whitespace() };

    p {
      border-top: none;
      padding: 0 ${ whitespace('less') } ${ whitespace('less') };
      width: auto;
    }

    span {
      font-size: 1rem;
      padding: 0 ${ whitespace('least') };

      span {
        font-size: .85rem;
        padding: 0 ${ whitespace('least') };
      }
    }

    h1 {
      .price {
        font-size: 5rem;
        margin-bottom: 0;
        margin-top: ${ whitespace() };

        .dollar-sign {
          font-size: 1.35rem;
          top: -1rem;
        }

        .name {
          margin-top: ${ whitespace('less') };
          margin-left: 0;
          font-size: 2rem;
          display: block;
          line-height: 2.5rem;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    p {
      border-top: 1px dotted ${ color('grey', 'darker') };
      padding: ${ whitespace() } 0;
      width: 50%;
    }

    span {
      font-size: 1.35rem;
      padding: 0 ${ whitespace('less') };

      span {
        font-size: .85rem;
        padding: 0 ${ whitespace('less') };
      }
    }

    h1 {
      .price {
        font-size: 5rem;
        margin-bottom: 0;
        margin-top: ${ whitespace() };

        .dollar-sign {
          font-size: 1.5rem;
          top: -18px;
        }

        .name {
          margin-top: ${ whitespace('less') };
          margin-bottom: ${ whitespace('less') };
          margin-left: ${ whitespace() };
          font-size: ${ whitespace() };
          font-size: 3rem;
        }
      }
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items:center;
  height: 100%;
  justify-content: center;
`;

const MerchTitle = styled.h1`
  margin: 0;
  text-align: center;

  span {
    display: block;
    font-weight: 100;

    span {
      display: inline-block;
      font-size: 1rem;
    }
  }
`;

const MerchImage = styled.a`
  z-index: 999;
  display: block;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  img {
    display: block;
  }

  @media only screen and (min-width: 0) {
    img {
      width: 100vw;
      border-bottom: 1px solid ${ color('grey', 'darker') };
    }
  }

  @media only screen and (min-width: 1200px) {
    padding-top: ${ whitespace('less') };
    padding-left: ${ whitespace('less') };
    padding-bottom: ${ whitespace('less') };

    img {
      height: 50vw;
      width: 50vw;
      border-bottom: none;
    }
  }

`;


function generateResizeUrl(key, width, height) {
  const domain = 'https://d2m5fus4qpj770.cloudfront.net/';
  const bucket = 'merchgame204211-production';

  return `${domain}${btoa(JSON.stringify({
    bucket,
    key: `public/${ key }`,
    edits: {
      resize: {
        width,
        height,
        fit: 'cover'
      }
    }
  }))}`;
};

class DisplayMerch extends React.Component {
  constructor(props) {
    super(props);

    this.merchItem = this.props.merch.find((merchItem) => {
      return merchItem.id === this.props.match.params.merch;
    });
  }

  close(e) {
    e.preventDefault();
    window.history.back();
  }

  render() {
    if (!this.merchItem) {
      return ( <Redirect to="/browse" /> );
    } else {
      return (
        <div>
          <Overlay />

          <StyledHeader>
            <Link onClick={(e) => this.close(e) } to="/browse">Music &amp; Merch</Link>
            <FontAwesomeIcon icon={ faChevronRight } />
            { this.merchItem.name }
          </StyledHeader>

          <MerchDetails>
            <ImageWrapper>
              <MerchImage target="_blank" title={ `Click to visit the ${ this.merchItem.artist.name } storefront.` } href={ this.merchItem.url }>
                <img src={ generateResizeUrl(this.merchItem?.imageKey, 1500, 1500) } />
              </MerchImage>
            </ImageWrapper>

            <DetailsWrapper>
              <div className="artist-logo" style={{ backgroundImage: `url(${ generateResizeUrl(this.merchItem.artist.logoKey, 1500, 1500) })` }}></div>

              <MerchTitle>
                <span className="price">
                  <span className="dollar-sign">$</span>
                  { this.merchItem?.price }
                  <span className="name">
                    { this.merchItem.name }
                  </span>
                </span>
                <span><span>by</span> { this.merchItem.artist.name || '' } <span>from</span> { this.merchItem.artist.locationLabel || '' }</span>
              </MerchTitle>

              <p>{ this.merchItem?.description }</p>

              { this.merchItem.artist.links && this.merchItem.artist.links.length > 0 &&
                <div>
                  <ul className="social">
                    { this.merchItem.artist.links[0] &&
                      this.merchItem.artist.links[0].url &&
                      <li>
                        <a target="_blank" href={ this.merchItem.artist.links[0].url }>
                          <FontAwesomeIcon
                            title="Facebook"
                            icon={ faFacebook }
                          />
                        </a>
                      </li>
                    }
                    { this.merchItem.artist.links[1] &&
                      this.merchItem.artist.links[1].url &&
                      <li>
                        <a target="_blank" href={ this.merchItem.artist.links[1].url }>
                          <FontAwesomeIcon
                            title="Twitter"
                            icon={ faTwitter }
                          />
                        </a>
                      </li>
                    }
                    { this.merchItem.artist.links[2] &&
                      this.merchItem.artist.links[2].url &&
                      <li>
                        <a target="_blank" href={ this.merchItem.artist.links[2].url }>
                          <FontAwesomeIcon
                            title="Instagram"
                            icon={ faInstagram }
                          />
                        </a>
                      </li>
                    }

                    { this.merchItem.artist.links.slice(3).map((link, i) =>
                      <li key={ i }>
                        <a target="_blank" href={ link.url }>
                          <FontAwesomeIcon
                            title={ link.label }
                            icon={ faLink }
                          />
                          <span>{ link.label }</span>
                        </a>
                      </li>
                    )}

                    { this.merchItem.artist.contactPoints &&
                      this.merchItem.artist.contactPoints.length > 0 &&
                      this.merchItem.artist.contactPoints.map((contact, i) => (
                        <li title={ contact.label } className="generic-link" key={ i }>
                          <FontAwesomeIcon
                            title={ contact.label }
                            icon={ faLink }
                          />
                        <span>{ contact.value }</span>
                        </li>
                      ))
                    }

                  </ul>

                  <TagList>
                    { this.merchItem.artist.genres.map((tag, i) =>
                      <Toggle
                        key={ i }
                        label={ tag }
                        type="button"
                        className='checked'
                      />
                    )}
                    { this.merchItem.categories.map((tag, i) =>
                      <Toggle
                        key={ i }
                        label={ tag }
                        type="button"
                        className='checked'
                      />
                    )}
                  </TagList>

                </div>
              }
            </DetailsWrapper>
          </MerchDetails>
        </div>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    merch: state.readMerch.merch
  }
};

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DisplayMerch));
