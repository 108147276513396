import {
  FETCH_MERCH,
  TOGGLE_FILTERS,
  UPDATE_FILTERS
} from 'state/workflows/read-merch/action-types';

export const fetchMerch = () => ({
  type: FETCH_MERCH
});

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  payload: filters
});
